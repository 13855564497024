import { getNoAuth, postNoAuth, putNoAuth } from '../noAuthService';
import { LocalStorageService } from '../localStorageService';
import { SessionStorageService } from '../sessionStorageService';
import { getAuthPure } from '../authService';
const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    USER_LOGIN: 'api/authenticate/send-sign-in-code',
    USER_LOGIN_GET_CODE: 'api/authenticate/sign-in',
    USER_FORGOT_PASSWORD_SEND_MAIL: 'api/authenticate/send-email-forget-password',
    USER_FORGOT_PASSWORD_CHECK_TOKEN: 'api/authenticate/check-valid-token-forget-password/',
    USER_FORGOT_PASSWORD_UPDATE: 'api/authenticate/change-password',
    USER_CHECK_VETIFY: 'api/authenticate/check-user-verify?email=',
    USER_RESENT_EMAIL: 'api/authenticate/resend-email-verify',
    USER_LOGOUT: 'api/user/sign-out',
};

export const postSendLoginCode = async (data) => {
    var result = await postNoAuth(API_URL + endPoint.USER_LOGIN, data);
    return result;
};

export const postLogin = async (data) => {
    data.isFirstTime = LocalStorageService.getIsLogin() !== 'true';
    var result = await postNoAuth(API_URL + endPoint.USER_LOGIN_GET_CODE, data);
    if (result?.data?.statusCode === 200) {
        LocalStorageService.setIsLogin('true');
        SessionStorageService.setSessionData(result?.data?.response);
    }
    return result;
};

export const postSendMailForgotPassword = async (data) => {
    return await postNoAuth(API_URL + endPoint.USER_FORGOT_PASSWORD_SEND_MAIL, data);
};

export const postReSendMail = async (data) => {
    return await postNoAuth(API_URL + endPoint.USER_RESENT_EMAIL, data);
};

export const postCheckToken = async (data) => {
    return await getNoAuth(API_URL + endPoint.USER_FORGOT_PASSWORD_CHECK_TOKEN + data);
};

export const checkUserVerify = async (data) => {
    return await getNoAuth(API_URL + endPoint.USER_CHECK_VETIFY + data);
};

export const putUpdatePassword = async (data) => {
    return await putNoAuth(API_URL + endPoint.USER_FORGOT_PASSWORD_UPDATE, data);
};

export const logout = async () => {
    return await getAuthPure(API_URL + endPoint.USER_LOGOUT);
};

export const getListRules = async () => {
    return await getAuthPure('https://api-dev-company-rule-fines.tkgplatform.com.au/api/rule-fines');
};
