import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionStorageService } from '../api';

const Signout = (props) => {
    // console.log('sign out');
    const navigate = useNavigate();
    React.useEffect(() => {
        SessionStorageService.removeSessionData();
        navigate('/login');
    }, [navigate]);
    return <></>;
};

export default Signout;
