import React from 'react'
import { ModalTKG } from 'tkg-composite-ui';
import { Row, Col, Typography, Card, Tag } from "antd"
import { RightSquareOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SessionStorageService } from '../../api';
import { redirectPage } from '../../pages/dashboard';
const { Title } = Typography;

const CardStyle = styled(Card)`
    transition: 0.3s all;
    border: 1px solid #dae1e7;
    &:hover {
        background: #3085d614;
        border-color: #3c8dbc6b !important;
        .ant-card-head {
            background: #62a8ea21;
        }
    }
`;

const DevelopmentWebsModal = (props) => {
    const { open, onClose, developmentWebs } = props
    return (


        <ModalTKG
            title="Development Websites"
            footer={false}
            onClose={onClose}
            open={open}
        >
            <Row gutter={[16, 16]}>
                {process.env.REACT_APP_ENVIRONTMENT === "development" ? null : <><Col span={24} md={6} className="container-col box-website">
                    <CardStyle
                        title="Go to"
                        bordered={false}
                        extra={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tag color="volcano" style={{ marginRight: 20 }}>
                                    DEVELOPMENT
                                </Tag>
                                <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_COMPANY_RULE_FINES_DEV}verify/`)} target="_blank">
                                    <RightSquareOutlined className="icon-b2c" />
                                </a>
                            </div>
                        }
                    >
                        <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_COMPANY_RULE_FINES_DEV}verify/`)} target="_blank">
                            <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                Company Policy & Info
                            </Title>
                        </a>
                    </CardStyle>
                </Col>
                    <Col span={24} md={6} className="container-col box-website">
                        <CardStyle
                            title="Go to"
                            bordered={false}
                            extra={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tag color="volcano" style={{ marginRight: 20 }}>
                                        DEVELOPMENT
                                    </Tag>
                                    <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_TR_DEV}verify/`)} target="_blank">
                                        <RightSquareOutlined className="icon-b2c" />
                                    </a>
                                </div>
                            }
                        >
                            <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_TR_DEV}verify/`)} target="_blank">
                                <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                    Time & Report
                                </Title>
                            </a>
                        </CardStyle>
                    </Col>
                    <Col span={24} md={6} className="container-col box-website">
                        <CardStyle
                            title="Go to"
                            bordered={false}
                            extra={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tag color="volcano" style={{ marginRight: 20 }}>
                                        DEVELOPMENT
                                    </Tag>
                                    <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_PAYMENT_CLAIMS_DEV}verify/`)} target="_blank">
                                        <RightSquareOutlined className="icon-b2c" />
                                    </a>
                                </div>
                            }
                        >
                            <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_PAYMENT_CLAIMS_DEV}verify/`)} target="_blank">
                                <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                    Payment & Claims
                                </Title>
                            </a>
                        </CardStyle>
                    </Col> <Col span={24} md={6} className="container-col box-website">
                        <CardStyle
                            title="Go to"
                            bordered={false}
                            extra={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tag color="volcano" style={{ marginRight: 20 }}>
                                        DEVELOPMENT
                                    </Tag>
                                    <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_TRAINING_MATERIAL_DEV}verify/`)} target="_blank">
                                        <RightSquareOutlined className="icon-b2c" />
                                    </a>
                                </div>
                            }
                        >
                            <a onClick={() => redirectPage(`${process.env.REACT_APP_DOMAIN_TRAINING_MATERIAL_DEV}verify/`)} target="_blank">
                                <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                    Training Material
                                </Title>
                            </a>
                        </CardStyle>
                    </Col>
                </>}


                {developmentWebs.map((userWebsite, index) => {
                    return (
                        <Col span={24} md={6} key={index} className="container-col box-website">
                            <CardStyle
                                title="Go to"
                                bordered={false}
                                extra={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        <Tag color="volcano" style={{ marginRight: 20 }}>
                                            DEVELOPMENT
                                        </Tag>
                                        {JSON.parse(userWebsite.website.endpoint_api)?.login ? JSON.parse(userWebsite.website.tags).includes("cs") ?
                                            <a onClick={() => redirectPage(`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}/`)} target="_blank">
                                                <RightSquareOutlined className="icon-b2c" />
                                            </a>
                                            :  <a
                                            target={'_blank'}
                                            href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}?email=${SessionStorageService.getUser()?.email
                                                }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                            rel="noreferrer"
                                        >
                                            <RightSquareOutlined className="icon-b2c" />
                                        </a> : <a
                                            target={'_blank'}
                                            href={`${userWebsite.website.domain}`}
                                            rel="noreferrer"
                                        >
                                            <RightSquareOutlined className="icon-b2c" />
                                        </a>}

                                    </div>
                                }
                            >
                                {JSON.parse(userWebsite.website.endpoint_api)?.login ? <a
                                    target={'_blank'}
                                    href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}?email=${SessionStorageService.getUser()?.email
                                        }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                    rel="noreferrer"
                                >
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        {userWebsite.website.name}
                                    </Title>
                                </a> : <a
                                    target={'_blank'}
                                    href={`${userWebsite.website.domain}`}
                                    rel="noreferrer"
                                >
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        {userWebsite.website.name}
                                    </Title>
                                </a>}
                            </CardStyle>
                        </Col>
                    );
                })}
            </Row>
        </ModalTKG>
    )
}

export default DevelopmentWebsModal
