import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment/moment';
import { ButtonDeleteTKG, TableTKG } from 'tkg-composite-ui';
import { isLoginSession, Constant, getListRole, deleteRole } from '../api';
import ModalAdd from '../components/role/modalAdd';
import { useNavigate } from 'react-router-dom';
import './role.scss';

const Role = (props) => {
    const navigate = useNavigate();
    const [listData, setListData] = React.useState([]);
    const [order, setOrder] = React.useState(0);
    const getRoles = async () => {
        const result = await getListRole();
        setOrder(Math.max(...result?.data?.response?.roles.map((o) => o.order)) + 1);
        return setListData(result?.data?.response);
    };

    React.useEffect(() => {
        if (isLoginSession() !== true) {
            return navigate(Constant.Pages.Login);
        }
        getRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: 'Name', key: 'name', dataIndex: 'name', align: 'left', isSearch: true },
        { title: 'Order', key: 'order', dataIndex: 'order', align: 'left' },
        { title: 'Create Date', key: 'createdAt', dataIndex: 'createdAt', align: 'left', render: (text, record) => moment(text).format('DD-MMM-YYYY') },
        {
            title: 'Action',
            key: 'typeId',
            dataIndex: 'typeId',
            align: 'center',
            width: '15%',
            render: (text, record) => (
                <Row>
                    <Col>
                        <ModalAdd roleItem={record} menus={listData.menus} callback={getRoles} order={order}></ModalAdd>
                    </Col>
                    <Col span={1}></Col>
                    <Col>
                        <ButtonDeleteTKG
                            id={record.id}
                            apiMethod={deleteRole}
                            message={Constant.ButtonDeleteTKGMessage.Role}
                            callback={getRoles}
                        ></ButtonDeleteTKG>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <div>
            <Row type="flex">
                <Col className="container-role" span={24}>
                    <ModalAdd menus={listData.menus} callback={getRoles} order={order}></ModalAdd>
                </Col>
            </Row>
            <Row type="flex">
                <Col className="container-role" span={24}>
                    <TableTKG columns={columns} dataSource={listData.roles} rowKey="id" />
                </Col>
            </Row>
        </div>
    );
};
export default Role;
