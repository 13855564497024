import React from 'react';
import { Form, Button, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { postCreateUser, Constant } from '../../api';
import { InputTKG, SelectTKG, PopupStatusTKG } from 'tkg-composite-ui';
import './modalAdd.scss';

const ModalAdd = ({ roleList, callback }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const showModal = () => {
        setIsModalOpen(true);
    };
    const hideModal = () => {
        setIsModalOpen(false);
    };

    const onClick = async (data) => {
        form.validateFields()
            .then((values) => {
                // console.log('validateFields values', values);
                postCreateUser(values).then((result) => {
                    if (result?.data?.statusCode === 200) {
                        PopupStatusTKG(Constant.PopupAlertTypes.Success, result.data.message);
                        form.resetFields();
                        hideModal();
                        callback();
                    } else {
                        PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.message);
                    }
                });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };
    return (
        <div className="add-user">
            <Button onClick={showModal} className="btnTKGAction modal-add-button" icon={<PlusCircleOutlined />}>
                Add New
            </Button>
            <Modal
                className="modal-add-user"
                title="User"
                open={isModalOpen}
                onCancel={hideModal}
                footer={[
                    <Button key="submit" className="btnTKGAction" type="primary" htmlType="submit" onClick={onClick}>
                        Save
                    </Button>,
                ]}
            >
                <Form form={form} name="modal-add-user" layout="vertical">
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the first name',
                            },
                            {
                                pattern: /^[A-Za-z .-]+$/,
                                message: 'Only accept letters, dots, dashes',
                            },
                        ]}
                    >
                        <InputTKG form={form} />
                    </Form.Item>
                    <Form.Item
                        name="surName"
                        label="Surname"
                        style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600, margin: '0 1rem' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the surname',
                            },
                            {
                                pattern: /^[A-Za-z .-]+$/,
                                message: 'Only accept letters, dots, dashes',
                            },
                        ]}
                    >
                        <InputTKG form={form} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the email',
                            },
                            {
                                type: 'email',
                                message: 'Please use the email address',
                            },
                        ]}
                    >
                        <InputTKG form={form} maxLength={80} />
                    </Form.Item>
                    <Form.Item
                        name="roleId"
                        label="Role"
                        style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600, margin: '0 1rem' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select the role',
                            },
                        ]}
                    >
                        <SelectTKG options={roleList} fieldNames={{ label: 'name', value: 'id' }}></SelectTKG>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the password',
                            },
                            {
                                validator(_, value) {
                                    if (!value || value.length === 0) {
                                        return Promise.resolve();
                                    }

                                    if (value.length < 6 || value.length > 30) {
                                        return Promise.reject(new Error('The password has at least 6 characters long, and maximum 30 characters'));
                                    }
                                    if (!/[A-Z]/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least one uppercase letter'));
                                    }
                                    if (!/[a-z]/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least one lowercase letter'));
                                    }
                                    if (!/\d{2}/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least 2 numbers'));
                                    }
                                    if (!/[^\w]/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least one special character'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputTKG isPasswordField={true} form={form} />
                    </Form.Item>
                    <Form.Item
                        name="passwordConfirm"
                        label="Password Confirm"
                        style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600, margin: '0 1rem' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm the password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <InputTKG isPasswordField={true} form={form} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalAdd;
