import { Dropdown, Layout, Typography, Drawer } from 'antd';
import Logo from '../../assets/images/logos/default-avatar.jpg';

import { LogoutOutlined, UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Constant, logout, SessionStorageService } from '../../api';
import { NavLink, useNavigate } from 'react-router-dom';
import HistoryLogin from '../dashboard/historyLogin';
import { useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';

const { Header } = Layout;
const LayoutHeader = ({ setLoading }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const useRole = SessionStorageService.getUser()?.role?.id;
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleLogout = async () => {




        logout().then(async (res) => {
            SessionStorageService.removeSessionData();
            const iframe = document.querySelectorAll('.iframe-web');
            iframe.forEach((item) => {
                item.contentWindow.postMessage({ type: 'logout' }, item.getAttribute('src'));
            });
            navigate('/login');
            console.log(auth.currentUser, "in then");
            if (auth && auth.currentUser) {
                console.log("logout firebase", auth.currentUser?.uid);
                await updateDoc(doc(db, 'users', auth.currentUser?.uid), {
                    is_online: false,
                })
                await signOut(auth)
            }
        })
            .catch(async (e) => {
                SessionStorageService.removeSessionData();
                const iframe = document.querySelectorAll('.iframe-web');
                iframe.forEach((item) => {
                    item.contentWindow.postMessage({ type: 'logout' }, item.getAttribute('src'));
                });
                navigate('/login');
                console.log(auth.currentUser, "in catch");
                if (auth && auth.currentUser) {
                    console.log("logout firebase", auth.currentUser?.uid);
                    await updateDoc(doc(db, 'users', auth.currentUser?.uid), {
                        is_online: false,
                    })
                    await signOut(auth)
                }
            });





    };

    const items = [
        {
            key: '1',
            label: <NavLink to={`/profile/${SessionStorageService.getUser()?.id}`}>My Profile</NavLink>,
            icon: <UserOutlined />,
        },
        useRole === 1 && {
            key: '4',
            label: <NavLink to={'/employee-manage'}>Manage Users</NavLink>,
            icon: <UserOutlined />,
        },
        useRole === 1 && {
            key: '3',
            label: <Typography onClick={() => showDrawer()}>History Login</Typography>,
            icon: <UnorderedListOutlined />,
        },
        {
            key: '2',
            label: (
                <Typography
                    onClick={() => {
                        setLoading(true);
                        handleLogout()
                    }}
                >
                    Logout
                </Typography>
            ),
            icon: <LogoutOutlined />,
        },
    ];
    return (
        <Header
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                width: '100%',
            }}
        >
            <div
                style={{
                    float: 'left',
                    cursor: 'pointer',
                }}
                onClick={() => navigate(Constant.Pages.Dashboard)}
            >
                <div className="logo">
                    <img className="logo" alt="Logo" src={Logo} width="75px" />
                </div>
            </div>
            <div style={{ textAlign: 'right', paddingLeft: 20, lineHeight: 1.2 }}>
                <span className="text-style-shadow h1">TWEET WORLD TRAVEL GROUP </span> <br />
                <span className="font-twt text-style-shadow h3">beyond your dreams</span>
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div
                style={{
                    float: 'right',
                }}
            >
                <div className="logo">
                    <Dropdown menu={{ items }} placement="bottomRight">
                        <Typography
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                cursor: 'pointer',
                            }}
                        >
                            My Account
                        </Typography>
                    </Dropdown>
                </div>
            </div>
            <Drawer placement="right" closable={false} onClose={onClose} open={open} key="right" width={window.innerWidth > 900 ? '70%' : '100%'}>
                <HistoryLogin />
            </Drawer>
        </Header>
    );
};

export default LayoutHeader;
