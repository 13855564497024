/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Typography, Col, Row, Card, Divider } from 'antd';
import { MailOutlined, DashboardOutlined, CreditCardOutlined, LoadingOutlined } from '@ant-design/icons';
import './dashboard.scss';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import 'moment-timezone';
import AccessPortal from '../components/dashboard/accessPortal';
// import HistoryLogin from '../components/dashboard/historyLogin';
// import { Timer } from '../components/clockInClockOut/Timer';
import Context from '../utils/context';
import { Constant, SessionStorageService, isLoginSession, getListRules, detailUser, refreshToken, logout } from '../api';
import Swal from 'sweetalert2';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

const { Title } = Typography;

export const redirectPage = async (url) => {
    let token = localStorage.getItem("accessToken");
    window.open(`${url}${token}`, '_blank');
};
export const refreshNewToken = async () => {
    const refresh = await refreshToken();
    let token = refresh.data.response.token;
    // console.log(token, 'refresh new token');
    sessionStorage.setItem('accessToken', token);
    const iframe = document.querySelectorAll('.iframe-web');
    iframe.forEach((item) => {
        item.onload = function () {
            item.contentWindow.postMessage({ type: 'refresh-token', token: token }, item.getAttribute('src'));
        };
        if (item.getAttribute('base-src')) {
            item.setAttribute('src', `${item.getAttribute('base-src')}verify/${token}?from-iframe=true`);
        }
    });
    sessionStorage.setItem('lifeTimeToken', process.env.REACT_APP_LIFE_TIME_TOKEN);
    sessionStorage.setItem('showConfirmLogout', false);
    return token;
};

const logoutAllSite = () => {
    logout()
        .then((res) => {
            SessionStorageService.removeSessionData();
            const iframe = document.querySelectorAll('.iframe-web');
            iframe.forEach((item) => {
                item.contentWindow.postMessage({ type: 'logout' }, item.getAttribute('src'));
            });
            window.location.replace('/login');
        })
        .catch((e) => {
            SessionStorageService.removeSessionData();
            const iframe = document.querySelectorAll('.iframe-web');
            iframe.forEach((item) => {
                item.contentWindow.postMessage({ type: 'logout' }, item.getAttribute('src'));
            });
            window.location.replace('/login');
        });
};

// const bc = new BroadcastChannel('twt');
//
// window.onload = function () {
//     window.addEventListener(
//         'message',
//         function (event) {
//             const { data, source } = event;
//             bc.postMessage(data);
//         },
//         false,
//     );
//     window.addEventListener('beforeunload', function () { });
//     if (sessionStorage.getItem('reloaded') != null) {
//         console.log('page was reloaded');
//         // SessionStorageService.removeSessionData();
//         // const iframe = document.querySelectorAll('.iframe-web');
//         // iframe.forEach((item) => {
//         //     item.contentWindow.postMessage({ type: 'logout' }, item.getAttribute('src'));
//         // });
//     } else {
//         console.log('page was close');
//     }
//
//     sessionStorage.setItem('reloaded', 'yes');
// };

const formatTime = (timer, getHour = true) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return getHour ? `${getHours} : ${getMinutes} : ${getSeconds}` : `${getMinutes} : ${getSeconds}`;
};

const Dashboard = () => {
    // bc.onmessage = useCallback(
    //     async (eventMessage) => {
    //         // const data = eventMessage.data;
    //         // // console.log(data);
    //         // if (data.type === 'refresh-token') {
    //         //     if (data.token) {
    //         //         sessionStorage.setItem('lifeTimeToken', process.env.REACT_APP_LIFE_TIME_TOKEN);
    //         //         sessionStorage.setItem('showConfirmLogout', false);
    //         //         sessionStorage.setItem('accessToken', data.token);
    //         //         const iframe = document.querySelectorAll('.iframe-web');
    //         //         iframe.forEach((item) => {
    //         //             item.contentWindow.postMessage({ type: 'refresh-token', token: data.token }, item.getAttribute('src'));
    //         //         });
    //         //     }
    //         // } else if (data.type === 'logout') {
    //         //     logoutAllSite();
    //         // } else if (data.type === 'refresh-token-new') {
    //         //     await refreshNewToken();
    //         //     Swal.close();
    //         // }
    //     },
    //     [bc],
    // );

    const navigate = useNavigate();
    const [websites, setWebsites] = useState(SessionStorageService.getUser()?.user_websites ?? []);
    const [dateState, setDateState] = useState(new Date());
    const token = sessionStorage.getItem('accessToken');
    const userRole = SessionStorageService.getUser()?.role?.id;
    const [countRules, setCountRules] = useState(<LoadingOutlined style={{ fontSize: 11 }} spin />);
    const timerTokenRef = useRef(null);
    const context = useContext(Context);
    const user = JSON.parse(localStorage.getItem("user"))

    useEffect(() => {
        // clearInterval(timerTokenRef.current);
        // timerTokenRef.current = setInterval(() => {
        //     sessionStorage.setItem('lifeTimeToken', sessionStorage.getItem('lifeTimeToken') - 1);
        //     if (parseInt(sessionStorage.getItem('lifeTimeToken')) < 1) logoutAllSite();
        //     if (
        //         parseInt(sessionStorage.getItem('lifeTimeToken')) <= parseInt(process.env.REACT_APP_TIME_SHOW_MODAL_LOGOUT) &&
        //         sessionStorage.getItem('showConfirmLogout') != null
        //     ) {
        //         sessionStorage.removeItem('showConfirmLogout');
        //         const iframe = document.querySelectorAll('.iframe-web');
        //         iframe.forEach((item) => {
        //             item.contentWindow.postMessage({ type: 'show-timer-logout', timer: sessionStorage.getItem('lifeTimeToken') }, item.getAttribute('src'));
        //         });
        //         let timerInterval;
        //         Swal.fire({
        //             position: 'top-end',
        //             icon: 'warning',
        //             title: 'Auto logout alert!',
        //             html: 'The system will automatically logout after  <br> <b style="font-size: 40px"></b>',
        //             timer: sessionStorage.getItem('lifeTimeToken') * 1000,
        //             timerProgressBar: true,
        //             didOpen: () => {
        //                 const b = Swal.getHtmlContainer().querySelector('b');
        //                 timerInterval = setInterval(() => {
        //                     b.textContent = formatTime(sessionStorage.getItem('lifeTimeToken'), false);
        //                 }, 1000);
        //             },
        //             willClose: () => {
        //                 clearInterval(timerInterval);
        //             },
        //             showClass: {
        //                 popup: 'animate__animated animate__fadeInDown',
        //             },
        //             hideClass: {
        //                 popup: 'animate__animated animate__fadeOutUp',
        //             },
        //             allowOutsideClick: false,
        //             allowEscapeKey: false,
        //             showCancelButton: true,
        //             showConfirmButton: true,
        //             confirmButtonText: 'Refresh',
        //             cancelButtonText: 'Close',
        //         }).then(async (result) => {
        //             /* Read more about handling dismissals below */
        //             if (result.isConfirmed) {
        //                 await refreshNewToken();
        //             }
        //             if (result.dismiss === Swal.DismissReason.timer) {
        //                 logoutAllSite();
        //             }
        //         });
        //     }
        // }, 1000);
        setInterval(() => {
            setDateState(new Date());
        }, 1000);
    }, []);

    const listUser = async () => {
        await getListRules().then((response) => {
            if (response?.data?.meta?.status === 200) {
                const rulesUnread = response?.data?.response?.filter((data) => !data?.viewers?.includes(SessionStorageService.getUser()?.id));
                setCountRules(rulesUnread.length);
            }
        });
    };
    useEffect(() => {
        if (isLoginSession() === false) {
            return navigate(Constant.Pages.Login);
        }
        listUser();
    }, []);
    const userDetail = async () => {
        const itemsUser = SessionStorageService.getUser()?.id;
        await detailUser(itemsUser).then((response) => {
            if (response?.data?.meta?.status === 200) {
                SessionStorageService.setUser(response?.data?.response);
            }
        });
    };

    const logicFireBase = async () => {
        console.log("vao");
        await signInWithEmailAndPassword(auth, user?.email ?? "", process.env.REACT_APP_SECRET_PASSWORD)
    }
    useEffect(() => {
        logicFireBase()
        userDetail();
    }, []);

    const formatDateTimeByTimeZone = (date, format) => {
        return moment.tz(date, 'YYYY-MM-DD hh:mm:ss', 'Australia/Sydney').utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format);
    };

    return (
        <>
            <div className="container">
                <div className="container-timer">
                    <Title level={1} style={{ marginBottom: 0, opacity: 0.7 }}>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                context.showModal();
                            }}
                        >
                            {dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}
                        </span>
                    </Title>
                </div>
            </div>
            <div className="container" style={{ marginTop: '100px' }}>
                <div style={{ marginBottom: 180 }}>
                    <Title level={4} style={{ marginBottom: 0 }}>
                        Welcome
                    </Title>
                    <Title style={{ marginTop: 0 }}>
                        {SessionStorageService.getUser()?.first_name} {SessionStorageService.getUser()?.surname}
                    </Title>
                    {SessionStorageService.getUser()?.last_sign_in_at ? (
                        <Title level={5}>
                            Last sign in: {formatDateTimeByTimeZone(SessionStorageService.getUser()?.last_sign_in_at, 'DD MMMM YYYY')} at{' '}
                            {formatDateTimeByTimeZone(SessionStorageService.getUser()?.last_sign_in_at, 'LTS')} {moment.tz.guess()}{' '}
                        </Title>
                    ) : null}
                </div>
            </div>

            <div style={{ background: 'rgb(235 247 255)' }}>
                <div className="container">
                    <Row gutter={[16, 16]}>
                        <Col span={24} md={8} className="container-col">
                            <Card bordered={false} style={{ marginTop: -95 }}>
                                <div onClick={() => redirectPage(process.env.REACT_APP_ENVIRONTMENT === "development" ? `${process.env.REACT_APP_DOMAIN_COMPANY_RULE_FINES_DEV}verify/` : `${process.env.REACT_APP_DOMAIN_COMPANY_RULE_FINES_PRODUCT}verify/`)} style={{ cursor: 'pointer' }}>
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        {/* <StyledBadge count={countRules}> */}
                                        <div
                                            style={{
                                                position: 'relative',
                                                display: 'inline',
                                                marginRight: 20,
                                            }}
                                        >
                                            <MailOutlined
                                                style={{
                                                    fontSize: 40,
                                                    position: 'relative',
                                                    top: 6,
                                                }}
                                            ></MailOutlined>
                                            {countRules > 0 ? <span className="badge-rules">{countRules}</span> : <></>}
                                        </div>
                                        {/* </StyledBadge> */}
                                        Company Policy & Info
                                    </Title>
                                </div>
                                <p className="container-description">General Rules, Privacy, Vacation Rules, Meeting & Report Rules, Fines Menu</p>
                            </Card>
                        </Col>
                        <Col span={24} md={8} className="container-col">
                            <Card bordered={false} style={{ marginTop: -95 }}>
                                <div onClick={() => redirectPage(process.env.REACT_APP_ENVIRONTMENT === "development" ? `${process.env.REACT_APP_DOMAIN_TR_DEV}verify/` : `${process.env.REACT_APP_DOMAIN_TR_PRODUCT}verify/`)} style={{ cursor: 'pointer' }}>
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        <DashboardOutlined
                                            style={{
                                                fontSize: 40,
                                                position: 'relative',
                                                top: 6,
                                                paddingRight: 20,
                                            }}
                                        />
                                        Time & Report
                                    </Title>
                                </div>
                                <p className="container-description">Leave application, your work etc.</p>
                            </Card>
                        </Col>
                        <Col span={24} md={8} className="container-col">
                            <Card bordered={false} style={{ marginTop: -95 }}>
                                <div onClick={() => redirectPage(process.env.REACT_APP_ENVIRONTMENT === "development" ? `${process.env.REACT_APP_DOMAIN_PAYMENT_CLAIMS_DEV}verify/` : `${process.env.REACT_APP_DOMAIN_PAYMENT_CLAIMS_PRODUCT}verify/`)} style={{ cursor: 'pointer' }}>
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        <CreditCardOutlined
                                            style={{
                                                fontSize: 40,
                                                position: 'relative',
                                                top: 6,
                                                paddingRight: 20,
                                            }}
                                        />
                                        Payment & Claims
                                    </Title>
                                </div>

                                <p className="container-description">Wages, commission, KPI bonus, other bonus, work-related expensive etc.</p>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Divider orientation="left">
                        <Title level={4} style={{ margin: 0, color: '#2778c4' }}>
                            Access Portal
                        </Title>
                    </Divider>
                    <br />
                    <AccessPortal websites={websites} userRole={userRole} token={token} />
                </div>
                <br />
                <br />
                <br />
            </div>
        </>
    );
};

export default Dashboard;
