import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Typography, Row, Col } from 'antd';
import { postSendMailForgotPassword, Constant } from '../../api';
import { InputTKG, PopupStatusTKG } from 'tkg-composite-ui';
import { DoubleRightOutlined, UserOutlined } from '@ant-design/icons';

const { Title } = Typography;

function FormEmail() {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [isLoading, setisLoading] = useState(false);

    const onFinish = async (data) => {
        form.validateFields().then((values) => {
            setisLoading(true);
            postSendMailForgotPassword(values).then((result) => {
                setisLoading(false);
                if (result?.data.meta?.status === 200) {
                    localStorage.setItem('email', values.email);
                    PopupStatusTKG(
                        Constant.PopupAlertTypes.Success,
                        'Please check your email Inbox or Spam/Junk folder for the reset password link.',
                        true,
                        20000,
                    );
                    form.resetFields();
                } else {
                    if (result.data.response.length !== 0) {
                        let reason = '';
                        Object.keys(result?.data.response).forEach((element) => {
                            reason += `\n${result?.data.response[element]}`;
                        });
                        PopupStatusTKG(Constant.PopupAlertTypes.Error, reason, true, 10000);
                    } else {
                        PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.meta?.message, true, 10000);
                    }
                    navigate(Constant.Pages.Login);
                }
            });
        });
    };

    return (
        <>
            <Form form={form} name="changePassword" layout="vertical" onFinish={onFinish}>
                <Title level={5} style={{ color: 'white' }}>
                    Forgot password
                </Title>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'Please use your email address',
                        },
                        {
                            required: true,
                            message: 'Please enter the email',
                        },
                    ]}
                >
                    <InputTKG form={form} maxLength={80} placeholder="Enter your email" prefix={<UserOutlined className="site-form-item-icon" />} />
                </Form.Item>
                <Title level={5} style={{ color: 'yellow', fontWeight: '200' }}>
                    You will receive a link to create a new password via email
                </Title>

                <Row justify="space-between">
                    <Col className="container-button"></Col>
                    <Col className="container-button">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btnTKGAction"
                            icon={<DoubleRightOutlined />}
                            loading={isLoading}
                            style={{ backgroundColor: 'yellow', color: 'black', fontWeight: 'bold' }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default FormEmail;
