const sessionKeys = {
    auth: "accessToken",
    sessionTimeoutInHours: "sessionTimeoutInHours",
    user: "user",
    role: "role",
    timer: "timer"
}

export const SessionStorageService = {
    setAuthData: function (data) {
        localStorage.setItem(sessionKeys.auth, data);
    },
    getAuthData: function () {
        return localStorage.getItem(sessionKeys.auth)
    },
    removeAuthData: function () {
        localStorage.removeItem(sessionKeys.auth);
    },
    removeTimer: function () {
        localStorage.removeItem(sessionKeys.timer);
    },
    getAuthHeader: function () {
        const accessToken = localStorage.getItem(sessionKeys.auth);
        if (accessToken) {
            return {
                'Access-Control-Allow-Origin': '*',
                //'Access-Control-Allow-Methods': ['get', 'post', 'put', 'delete'],
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };
          }
        return {};
    },
    setSessionTimeoutInHours: function (data) {
        localStorage.setItem(sessionKeys.sessionTimeoutInHours, data);
    },
    getSessionTimeoutInHours: function () {
        let data = localStorage.getItem(sessionKeys.sessionTimeoutInHours)
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    removeSessionTimeoutInHours: function () {
        localStorage.removeItem(sessionKeys.sessionTimeoutInHours);
    },

    setUser: function (data) {
        localStorage.setItem(sessionKeys.user, JSON.stringify(data));
    },
    getUser: function () {
        let data = localStorage.getItem(sessionKeys.user)
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    removeUser: function () {
        localStorage.removeItem(sessionKeys.user);
    },

    setRole: function (data) {
        localStorage.setItem(sessionKeys.role, JSON.stringify(data));
    },
    getRole: function () {
        let data = localStorage.getItem(sessionKeys.role)
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    removeRole: function () {
        localStorage.removeItem(sessionKeys.role);
    },

    setSessionData: function(data) {
        this.setAuthData(data.accessToken);
        // this.setSessionTimeoutInHours(data.sessionTimeoutInHours);
        this.setUser(data.user);
        // this.setRole(data.role);
    },
    removeSessionData: function () {
        console.log('remove session data')
        this.removeAuthData();
        this.removeSessionTimeoutInHours();
        this.removeUser();
        this.removeRole();
        this.removeTimer();
    },

    isLogin: function () {
        return !!this.getAuthData();
    }
}

export const isLoginSession = () => {
    var result = SessionStorageService.isLogin();
    return result;
}

export const setSessionData = (data) => {
    SessionStorageService.setSessionData(data);
}

export const removeSessionData = async () => {
    return SessionStorageService.removeSessionData();
}
