import { Layout, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Constant, isLoginSession } from '../../api';
import Context from '../../utils/context';
import { Timer } from '../clockInClockOut/Timer';
import LayoutFooter from './footer';
import LayoutHeader from './header';
const { Content } = Layout;
const LayoutCommon = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const context = useContext(Context);

    useEffect(() => {
        if (isLoginSession() === false) {
            return navigate(Constant.Pages.Login);
        }
    }, [navigate]);
    return (
        <Spin spinning={loading}>
            <Layout>
                <LayoutHeader setLoading={setLoading} />
                <Timer
                    isOpenModal={context.isOpenModal}
                    handleCancel={() => {
                        context.hideModal();
                    }}
                />
                <Content
                    style={{
                        margin: '24px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: `calc(100vh - 190px)`,
                        }}
                    >
                        <Outlet />
                    </div>
                </Content>
                <LayoutFooter />
            </Layout>
        </Spin>
    );
};
export default LayoutCommon;
