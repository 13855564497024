import { Layout } from 'antd';

const { Footer } = Layout;

const LayoutFooter = () => {
    return (

        <Footer
            style={{
                textAlign: 'center',
            }}
        >
            © Copyright 2023 - Powered by TKG Platform Technology
        </Footer>
    );
};

export default LayoutFooter;
