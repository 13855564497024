/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Typography, Col, Row, Card, Spin, Space, Form, Button } from 'antd';
import './dashboard.scss';
import './profile.scss';
import { useNavigate, useParams } from 'react-router';
import dayjs from 'dayjs';
import { SessionStorageService, Constant, isLoginSession, detailUser, updateUser, getTimeOff } from '../api';
import HistoryUser from './historyUser';
import { InputTKG, InputNumberTKG, DatePickerTKG, TextEditorTKG, SelectTKG, PopupStatusTKG } from 'tkg-composite-ui';
import parse from 'html-react-parser';

const format_money = (money) => Intl.NumberFormat('en-US').format(money);

const Profile = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [detail, setUserDetail] = useState({});
    const [timeoff, setTimeOff] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editable, setEditable] = useState(false);
    const positions = [
        { value: 'staff', label: 'Staff' },
        { value: 'manages', label: 'Manages' },
    ];
    const groups = [
        { value: 'TWT Team', label: 'TWT Team' },
        { value: 'OVC Team', label: 'OVC Team' },
        { value: 'IT Team', label: 'IT Team' },
    ];

    const userDetails = async (user_id) => {
        await detailUser(user_id).then((response) => {
            if (response?.data?.meta?.status === 200) {
                setLoading(false);
                setUserDetail(response?.data?.response);
            }
        });

        await getTimeOff({ user_id: user_id, status: 2 }).then((res) => {
            if (res?.status === 200) {
                setTimeOff(res?.data?.response?.filter((e) => e?.policy === "Annual Leave"))
            }
        })
    };

    const countDay = (start, end) => {
        let startNow = new Date(start);
        let count = 0;
        let diff = dayjs(end).diff(dayjs(start), "day") + 1;
        for (let i = 0; i < diff; i++) {
            const newStart = startNow.setDate(new Date(start).getDate() + i);
            const lastCount = new Date(newStart).getDay();
            if (Number(lastCount) !== 0 && Number(lastCount) !== 6) {
                count = count + 1;
            }
        }
        return count;
    }

    const enableEdit = () => {
        form.setFieldsValue({ ...detail, dob: detail?.dob ? dayjs(detail?.dob) : '' });
        setEditable(true);
    };

    const cancelEdit = () => {
        setEditable(false);
    };

    useEffect(() => {
        if (isLoginSession() === false) {
            return navigate(Constant.Pages.Login);
        }
        if (SessionStorageService.getUser()?.role?.id !== 1 && SessionStorageService.getUser()?.id !== +id) {
            return navigate(`/profile/${SessionStorageService.getUser()?.id}`);
        }
        userDetails(+id);
    }, [id, navigate]);

    const onFinish = async (values) => {
        setLoading(true);
        const dob = values['dob'];
        const data = {
            ...values,
            dob: dob ? dob?.format('YYYY-MM-DD') : '',
        };
        await updateUser(+id, data).then((res) => {
            if (res?.data?.meta?.status === 200) {
                setLoading(false);
                userDetails(+id);
                setEditable(false);
                form.resetFields();
                PopupStatusTKG('success', 'Update Profile Successfuly!');
            } else {
                setLoading(false);
            }
        });
    };

    return (
        <div className="container" style={{ padding: '5% 0' }}>
            <Spin spinning={loading}>
                <Card
                    title="Profile"
                    extra={
                        <Space>
                            <HistoryUser userId={+id} />
                        </Space>
                    }
                >
                    <Form layout="vertical" onFinish={onFinish} form={form} autoComplete="off" initialValues={{}}>
                        <Row gutter={[16, 30]}>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>First Name:</Typography>
                                        <Typography>{detail?.first_name}</Typography>
                                    </Space>
                                ) : (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="first_name"
                                        label="First name:"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input first name',
                                            },
                                        ]}
                                    >
                                        <InputTKG form={form} placeholder="Please input first name" />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Surname:</Typography>
                                        <Typography>{detail?.surname}</Typography>
                                    </Space>
                                ) : (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="surname"
                                        label="Surname:"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input surname',
                                            },
                                        ]}
                                    >
                                        <InputTKG form={form} placeholder="Please input surname" />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                <Space>
                                    <Typography style={{ fontWeight: 600 }}>Email:</Typography>
                                    <Typography>{detail?.email}</Typography>
                                </Space>
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Phone:</Typography>
                                        <Typography>{detail?.phone}</Typography>
                                    </Space>
                                ) : (
                                    <Form.Item style={{ marginBottom: 0 }} name="phone" label="Phone:">
                                        <InputTKG form={form} placeholder="Please input phone" />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Date of birth:</Typography>
                                        <Typography>{detail?.dob ? dayjs(detail?.dob).format('DD-MMM-YYYY') : ''}</Typography>
                                    </Space>
                                ) : (
                                    <Form.Item style={{ marginBottom: 0 }} name="dob" label="Date of birth:">
                                        <DatePickerTKG form={form} format={'DD-MMM-YYYY'} />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={12} lg={4} md={6} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Position:</Typography>
                                        <Typography className="capitalizeText">{detail?.position}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="position"
                                        label="Position:"
                                        rules={[{ required: true, message: 'Please select position' }]}
                                    >
                                        <SelectTKG form={form} placeholder="Please select position" options={positions}></SelectTKG>
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Position:</Typography>
                                        <Typography className="capitalizeText">{detail?.position}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} lg={4} md={6} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Group:</Typography>
                                        <Typography className="capitalizeText">{detail?.group}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="group"
                                        label="Group:"
                                        rules={[{ required: true, message: 'Please select group' }]}
                                    >
                                        <SelectTKG form={form} placeholder="Please select group" options={groups}></SelectTKG>
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Group:</Typography>
                                        <Typography className="capitalizeText">{detail?.group}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Our Rate Hour - Day:</Typography>
                                        <Typography>{format_money(detail?.rate)}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="rate"
                                        label="Our Rate Hour - Day:"
                                    // rules={[{ required: true, message: 'Please input Our Rate Hour - Day' }]}
                                    >
                                        <InputNumberTKG
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            form={form}
                                            placeholder="Please input Our Rate Hour - Day"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Our Rate Hour - Day:</Typography>
                                        <Typography>{format_money(detail?.rate)}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Yearly Salary:</Typography>
                                        <Typography>{format_money(detail?.yearly_salary)}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="yearly_salary"
                                        label="Yearly Salary:"
                                    // rules={[{ required: true, message: 'Please input Yearly Salary' }]}
                                    >
                                        <InputNumberTKG
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            form={form}
                                            placeholder="Please input Yearly salary"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Yearly Salary:</Typography>
                                        <Typography>{format_money(detail?.yearly_salary)}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Yearly Fix Bonus:</Typography>
                                        <Typography>{format_money(detail?.bonus)}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="bonus"
                                        label="Yearly Fix Bonus:"
                                    // rules={[{ required: true, message: 'Please input Yearly Fix Bonus' }]}
                                    >
                                        <InputNumberTKG
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            form={form}
                                            placeholder="Please input Yearly Fix Bonus"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Yearly Fix Bonus:</Typography>
                                        <Typography>{format_money(detail?.bonus)}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Overtime Normal:</Typography>
                                        <Typography>{format_money(detail?.overtime_normal)}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item style={{ marginBottom: 0 }} name="overtime_normal" label="Overtime Normal:">
                                        <InputNumberTKG formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} form={form} />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Overtime Normal:</Typography>
                                        <Typography>{format_money(detail?.overtime_normal)}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Overtime Weekend:</Typography>
                                        <Typography>{format_money(detail?.overtime_weekend)}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item style={{ marginBottom: 0 }} name="overtime_weekend" label="Overtime Weekend:">
                                        <InputNumberTKG formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} form={form} />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Overtime Weekend:</Typography>
                                        <Typography>{format_money(detail?.overtime_weekend)}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={12} lg={8} md={12} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Overtime Holiday:</Typography>
                                        <Typography>{format_money(detail?.overtime_holiday)}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item style={{ marginBottom: 0 }} name="overtime_holiday" label="Overtime Holiday:">
                                        <InputNumberTKG formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} form={form} />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Overtime Holiday:</Typography>
                                        <Typography>{format_money(detail?.overtime_holiday)}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={8} lg={8} md={8} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Currency:</Typography>
                                        <Typography>{detail?.currency}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="currency"
                                        label="Currency:"
                                        rules={[{ required: true, message: 'Please select Currency' }]}
                                    >
                                        <SelectTKG options={Constant.Currency} />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Currency:</Typography>
                                        <Typography>{detail?.currency}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={8} lg={8} md={8} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Bank Number:</Typography>
                                        <Typography>{detail?.bank_number}</Typography>
                                    </Space>
                                ) : (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="bank_number"
                                        label="Bank Number:"
                                    // rules={[{ required: true, message: 'Please input Bank Number' }]}
                                    >
                                        <InputTKG form={form} placeholder="Please input Bank Number" />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={8} lg={8} md={8} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Bank Name:</Typography>
                                        <Typography>{detail?.bank_name}</Typography>
                                    </Space>
                                ) : (
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                        name="bank_name"
                                        label="Bank Name:"
                                    // rules={[{ required: true, message: 'Please input Bank Name' }]}
                                    >
                                        <InputTKG form={form} placeholder="Please input Bank Name" />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={8} lg={8} md={8} xs={24}>
                                {!editable ? (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Day Off Exist:</Typography>
                                        <Typography>{format_money(detail?.day_off)}</Typography>
                                    </Space>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item style={{ marginBottom: 0 }} name="day_off" label="Day Off Exist:">
                                        <InputNumberTKG
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            form={form}
                                            placeholder="Please input Day Off Exist"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Space>
                                        <Typography style={{ fontWeight: 600 }}>Yearly Salary:</Typography>
                                        <Typography>{format_money(detail?.day_off)}</Typography>
                                    </Space>
                                )}
                            </Col>
                            <Col span={24}>
                                <Typography style={{ fontWeight: 600 }}>Used leave days (excluding Saturday, Sunday):</Typography>
                                <Typography>
                                    <ul>
                                        {timeoff?.length > 0 && timeoff?.map((e, i) => (
                                            <li key={i} style={{ marginTop: 5, color: 'green' }}>
                                                {`${dayjs(e?.start_time).format('DD-MMM-YYYY')} -> ${dayjs(e?.end_time).format('DD-MMM-YYYY')} - ${countDay(e?.start_time, e?.end_time)} ${countDay(e?.start_time, e?.end_time) === 1 ? 'day' : 'days'}`}
                                            </li>
                                        ))}
                                    </ul>
                                </Typography>
                            </Col>
                            <Col span={24}>
                                {!editable ? (
                                    <>
                                        <Typography style={{ fontWeight: 600 }}>Note:</Typography>
                                        <Typography>{detail?.note ? parse(detail?.note) : ''}</Typography>
                                    </>
                                ) : SessionStorageService.getUser()?.role?.id === 1 ? (
                                    <Form.Item style={{ marginBottom: 0 }} name="note" label="Note:">
                                        <TextEditorTKG form={form} />
                                    </Form.Item>
                                ) : (
                                    <>
                                        <Typography style={{ fontWeight: 600 }}>Note:</Typography>
                                        <Typography>{detail?.note ? parse(detail?.note) : ''}</Typography>
                                    </>
                                )}
                            </Col>
                            <Col span={24}>
                                <Form.Item style={{ marginBottom: 0, float: 'right' }}>
                                    {!editable ? (
                                        <Button type="primary" onClick={enableEdit}>
                                            Edit Profile
                                        </Button>
                                    ) : (
                                        <Space>
                                            <Button loading={loading} type="primary" htmlType="submit">
                                                Save
                                            </Button>
                                            <Button loading={loading} type="default" onClick={cancelEdit}>
                                                Cancel
                                            </Button>
                                        </Space>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Spin>
        </div>
    );
};

export default Profile;
