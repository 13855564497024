/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import { verify, SessionStorageService, Constant } from '../api';

const Verify = () => {
    const navigate = useNavigate();
    const param = useParams();
    const token = atob(param.token);
    const [loading, setLoading] = useState('true');
    const [failed, setFailed] = useState(false);
    sessionStorage.setItem('accessToken', token);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const fetchVerify = async () => {
            return await verify(token);
        };
        fetchVerify().then((response) => {
            setLoading(false);
            if (response?.data?.meta?.status === 200) {
                SessionStorageService.setUser(response?.data?.response);
                // console.log('verify', response?.data?.response);
                if (searchParams.get('from-iframe') != null) {
                    sessionStorage.setItem('from-iframe', JSON.stringify(true));
                } else {
                    sessionStorage.setItem('from-iframe', JSON.stringify(false));
                }
                navigate(Constant.Pages.Dashboard);
            } else {
                setFailed(true);
            }
        });
    }, []);

    return (
        <div className="verify-background">
            {loading && <Spin size="large" style={{ margin: 'auto' }} />}
            {failed && (
                <div
                    style={{
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography className="title-verify title-verify-failed">You have no permission!</Typography>
                    <CloseCircleOutlined
                        style={{
                            fontSize: '120px',
                            color: 'red',
                            margin: 'auto',
                        }}
                    />
                    <br />
                </div>
            )}
        </div>
    );
};

export default Verify;
