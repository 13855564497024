import { getNoAuth, postNoAuth } from '../noAuthService';
const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    REGISTER: 'api/authenticate/register',
    VERIFY_ACCOUNT: 'api/authenticate/verify-account/',
    VERIFY: 'api/user/verify/',
};

export const postRegister = async (data) => {
    var result = await postNoAuth(API_URL + endPoint.REGISTER, data);
    return result;
};

export const verifyRegiter = async (data) => {
    var result = await getNoAuth(API_URL + endPoint.VERIFY_ACCOUNT + data);
    return result;
};

export const verify = async (data) => {
    var result = await getNoAuth(API_URL + endPoint.VERIFY + data);
    return result;
};
