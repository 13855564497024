import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment/moment';
import { ButtonDeleteTKG, SwitchActiveTKG, TableTKG } from 'tkg-composite-ui';
import { isLoginSession, Constant, getListUser, deleteUser, putStatusUser } from '../api';
import UpdateRole from '../components/user/updateRole';
import ModalAdd from '../components/user/modalAdd';
import { useNavigate } from 'react-router-dom';
import './user.scss';

const User = (props) => {
    const navigate = useNavigate();
    const [listData, setListData] = React.useState({ roles: [], users: [] });
    const getUsers = async () => {
        const result = await getListUser();
        setListData(result?.data?.response);
    };

    React.useEffect(() => {
        if (isLoginSession() !== true) {
            return navigate(Constant.Pages.Login);
        }
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { title: 'Name', key: 'fullName', dataIndex: 'fullName', align: 'left', isSearch: true },
        { title: 'Email', key: 'email', dataIndex: 'email', align: 'left', isSearch: true },
        { title: 'Create Date', key: 'createdAt', dataIndex: 'createdAt', align: 'left', render: (text, record) => moment(text).format('DD-MMM-YYYY') },
        {
            title: 'Role',
            key: 'roleId',
            dataIndex: 'roleId',
            align: 'left',
            render: (text, record) => <UpdateRole id={record.id} roleId={record.roleId} roleList={listData?.roles} callback={getUsers}></UpdateRole>,
        },
        {
            title: 'Action',
            align: 'center',
            render: (text, record) => (
                <Row>
                    <Col>
                        <ButtonDeleteTKG
                            id={record.id}
                            apiMethod={deleteUser}
                            message={Constant.ButtonDeleteTKGMessage.User}
                            callback={getUsers}
                        ></ButtonDeleteTKG>
                    </Col>
                    <Col span={1}></Col>
                    <Col>
                        <SwitchActiveTKG
                            id={record.id}
                            checkedChildren="Active"
                            unCheckedChildren="Deactive"
                            isActive={record.isActive}
                            apiMethod={putStatusUser}
                            callback={getUsers}
                            activeMessage={Constant.SwitchActiveTKGMessage.ActiveUser}
                            deactiveMessage={Constant.SwitchActiveTKGMessage.DeactiveUser}
                        ></SwitchActiveTKG>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <>
            <div>
                <Row type="flex">
                    <Col className="container-add-user" span={24}>
                        <ModalAdd roleList={listData?.roles} callback={getUsers}></ModalAdd>
                    </Col>
                </Row>
                <Row type="flex">
                    <Col className="container-user" span={24}>
                        <TableTKG columns={columns} dataSource={listData.users} rowKey="id" />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default User;
