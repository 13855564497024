/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Typography, Row, Col, notification, message } from 'antd';
import { postRegister, Constant } from '../../api';
import { InputTKG, PopupCustomConfirmButtonTKG } from 'tkg-composite-ui';
import { DoubleRightOutlined, UserOutlined, LockOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import './formRegister.scss';

const { Title, Paragraph } = Typography;

function FormRegister() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [lockedAccount, setLockedAccount] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement, data) => {
        api.info({
            message: data,
            placement,
        });
    };

    const onRegister = async (data) => {
        setIsRegister(true);
        var result = await postRegister(data);
        setIsRegister(false);
        if (result.data.meta.status === 200) {
            PopupCustomConfirmButtonTKG(
                'success',
                'BACK TO LOGIN',
                'A verification link was sent to your email. \n Please check your email to verify',
                onBackToLogin,
            );
        } else {
            if (result.data.response.length !== 0) {
                Object.keys(result.data.response).forEach((item) => {
                    openNotification('topRight', result.data.response[item]);
                });
            } else {
                openNotification('topRight', result.data.meta.message);
            }
        }
    };

    const onBackToLogin = () => {
        navigate('/login');
    };

    return (
        <>
            <Form layout="vertical" form={form} onFinish={onRegister}>
                {contextHolder}
                <Typography className="tittle-login-form">REGISTRATION</Typography>
                <Row className="register-form" gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the first name',
                                },
                            ]}
                        >
                            <InputTKG form={form} maxLength={20} placeholder="First name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="surname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the surname',
                                },
                            ]}
                        >
                            <InputTKG form={form} maxLength={20} placeholder="Surname" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="register-form" justify="center">
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please use your email address',
                                },
                                {
                                    required: true,
                                    message: 'Please enter the email',
                                },
                            ]}
                        >
                            <InputTKG form={form} maxLength={80} placeholder="Email" prefix={<UserOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="register-form" gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the password',
                                },
                                {
                                    validator(_, value) {
                                        if (value.length < 6 || value.length > 30) {
                                            return Promise.reject(new Error('The password has at least 6 characters long, and maximum 30 characters'));
                                        }
                                        if (!/[A-Z]/.test(value)) {
                                            return Promise.reject(new Error('The password contains at least one uppercase letter'));
                                        }
                                        if (!/[a-z]/.test(value)) {
                                            return Promise.reject(new Error('The password contains at least one lowercase letter'));
                                        }
                                        if (!/\d{2}/.test(value)) {
                                            return Promise.reject(new Error('The password contains at least 2 numbers'));
                                        }
                                        if (!/[^\w]/.test(value)) {
                                            return Promise.reject(new Error('The password contains at least one special character'));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputTKG isPasswordField={true} form={form} placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="confirmPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the confirm password',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <InputTKG
                                isPasswordField={true}
                                form={form}
                                placeholder="Confirm password"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="register-button-group" justify="space-between">
                    <Col className="container-button">
                        <Button onClick={() => navigate(Constant.Pages.Login)} type="default" icon={<DoubleLeftOutlined />}>
                            Back
                        </Button>
                    </Col>

                    <Col className="container-button">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btnTKGAction"
                            disabled={lockedAccount}
                            icon={<DoubleRightOutlined />}
                            loading={isRegister}
                        >
                            Next
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={16} md={24}>
                        <Title level={5} style={{ color: 'white' }}>
                            Passwords must:
                        </Title>
                        <Paragraph>
                            <ul className="password-guideline">
                                <li>+ Be at least 6 characters long, and maximum 30 characters</li>
                                <li>+ Contain at least one uppercase letter</li>
                                <li>+ Contain at least one lowercase letter</li>
                                <li>+ Contain at least 2 numbers</li>
                                <li>+ Contain at least one special character</li>
                                <li>+ Enter and Confirm passwords match</li>
                            </ul>
                        </Paragraph>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default FormRegister;
