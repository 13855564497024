import React from 'react'
import { ModalTKG } from 'tkg-composite-ui';
import { Row, Col, Typography, Card, Tag } from "antd"
import { RightSquareOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SessionStorageService } from '../../api';
import { redirectPage } from '../../pages/dashboard';
const { Title } = Typography;

const CardStyle = styled(Card)`
    transition: 0.3s all;
    border: 1px solid #dae1e7;
    &:hover {
        background: #3085d614;
        border-color: #3c8dbc6b !important;
        .ant-card-head {
            background: #62a8ea21;
        }
    }
`;

const DashboardWebsiteModal = (props) => {
    const { open, onClose, dashboardWebsite } = props
    return (


        <ModalTKG
            title="Dashboard Websites"
            footer={false}
            onClose={onClose}
            open={open}
        >
            <Row gutter={[16, 16]}>



                {dashboardWebsite.map((userWebsite, index) => {
                    return (
                        <Col span={24} md={6} key={index} className="container-col box-website">
                            <CardStyle
                                title="Go to"
                                bordered={false}
                                extra={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                        <Tag color={userWebsite.website.environment == 'development' ? 'volcano' : 'green'} style={{ marginRight: 20 }}>
                                            {userWebsite.website.environment == 'development' ? 'Development' : 'Production'}
                                        </Tag>
                                        {JSON.parse(userWebsite.website.endpoint_api)?.login ? <a
                                            target={'_blank'}
                                            href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}?email=${SessionStorageService.getUser()?.email
                                            }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                            rel="noreferrer"
                                        >
                                            <RightSquareOutlined className="icon-b2c" />
                                        </a>  : <a
                                            target={'_blank'}
                                            href={`${userWebsite.website.domain}`}
                                            rel="noreferrer"
                                        >
                                            <RightSquareOutlined className="icon-b2c" />
                                        </a>}

                                    </div>
                                }
                            >
                                {JSON.parse(userWebsite.website.endpoint_api)?.login ? <a
                                    target={'_blank'}
                                    href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}?email=${SessionStorageService.getUser()?.email
                                    }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                    rel="noreferrer"
                                >
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        {userWebsite.website.name}
                                    </Title>
                                </a>  : <a
                                    target={'_blank'}
                                    href={`${userWebsite.website.domain}`}
                                    rel="noreferrer"
                                >
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        {userWebsite.website.name}
                                    </Title>
                                </a>}
                            </CardStyle>
                        </Col>
                    );
                })}
            </Row>
        </ModalTKG>
    )
}

export default DashboardWebsiteModal
