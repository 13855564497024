import axios from 'axios';


export const getNoAuth = async url => {
    try {
        return await axios.get(url);
    } catch (e) {
        console.log('getNoAuth ex', e);
        return e.response
    }
}
export const postNoAuth = async (url, data) => {
    try {
        return await axios.post(url, data);
    } catch (e) {
        console.log(e.response)
        return e.response
    }
}

export const putNoAuth = async (url, data) => {
    try {
        return await axios.put(url, data);
    } catch (e) {
        console.log('putNoAuth ex', e);
    }
}