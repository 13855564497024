export const ACTION = {
    LUNCH_BREAK: 'Lunch Break',
    BREAK: 'Break',
    RESUME_WORK: 'Resume work',
};

export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const API_TYPE = {
    ONE_LOGIN: 0,
    TIME_REPORT: 1,
};
