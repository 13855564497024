import { Button, Card, Timeline, Tag, Typography, Space, Spin, Tabs } from 'antd';
import { useState } from 'react';
import { ModalFormTKG } from 'tkg-composite-ui';
import { getListHistory } from '../api';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import parse from 'html-react-parser';

const format_money = (money) => Intl.NumberFormat('en-US').format(money);

const HistoryUser = ({ userId }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [dataWage, setDataWage] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleOpen = () => {
        setOpen(true);
        fecthListHistory();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fecthListHistory = async () => {
        await getListHistory(userId).then((res) => {
            if (res?.data?.meta?.status === 200) {
                setData(res.data.response[0]);
                setDataWage(res.data.response[1]);
                setLoading(false);
            }
        });
    };

    const LogWageTimeLine = () => {
        return (
            <Timeline
                mode="left"
                items={dataWage?.map((item) => {
                    return {
                        dot: <EditOutlined className="icon-history" />,
                        children: (
                            <div className="content-history">
                                <p>
                                    <b>
                                        {item?.updated_by?.first_name + ' ' + item?.updated_by?.surname}
                                        &nbsp;-&nbsp;
                                        <span className="capitalizeText">Edit {item?.type}</span>
                                    </b>
                                    &nbsp;&nbsp;
                                    <Tag style={{ margin: 0 }}>{moment(item?.created_at).format('DD-MMM-YYYY, H:m A')}</Tag>
                                </p>
                                <Card style={{ borderRadius: '0.75rem', background: '#f0f2fc' }}>
                                    {[JSON.parse(item?.event)]?.map((e, i) => (
                                        <div key={i}>
                                            {e?.old_data['yearly_salary'] || e?.new_data['yearly_salary'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Yearly Salary</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['yearly_salary'] && e?.old_data['yearly_salary'] !== ''
                                                                ? format_money(e?.old_data['yearly_salary'])
                                                                : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{format_money(e?.new_data['yearly_salary'])}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ))}
                                </Card>
                            </div>
                        ),
                    };
                })}
            />
        )
    }

    const LogTimeLine = () => {
        return (
            <Timeline
                mode="left"
                items={data?.map((item) => {
                    return {
                        dot: <EditOutlined className="icon-history" />,
                        children: (
                            <div className="content-history">
                                <p>
                                    <b>
                                        {item?.updated_by?.first_name + ' ' + item?.updated_by?.surname}
                                        &nbsp;-&nbsp;
                                        <span className="capitalizeText">{item?.type} Profile</span>
                                    </b>
                                    &nbsp;&nbsp;
                                    <Tag style={{ margin: 0 }}>{moment(item?.created_at).format('DD-MMM-YYYY, H:m A')}</Tag>
                                </p>
                                <Card style={{ borderRadius: '0.75rem', background: '#f0f2fc' }}>
                                    {[JSON.parse(item?.event)]?.map((e, i) => (
                                        <div key={i}>
                                            {e?.old_data['active'] || e?.new_data['active'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Status</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>{e?.old_data['active'] === 1 ? 'Active' : 'Deactive'}</Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>
                                                            {e?.new_data['active'] === 1 ? 'Active' : 'Deactive'}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['currency'] || e?.new_data['currency'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Currency</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['currency'] && e?.old_data['currency'] !== '' ? e?.old_data['currency'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{e?.new_data['currency']}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['first_name'] || e?.new_data['first_name'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>First Name</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['first_name'] && e?.old_data['first_name'] !== '' ? e?.old_data['first_name'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{e?.new_data['first_name']}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['surname'] || e?.new_data['surname'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Surname</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['surname'] && e?.old_data['surname'] !== '' ? e?.old_data['surname'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{e?.new_data['surname']}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['email'] || e?.new_data['email'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Email</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['email'] && e?.old_data['email'] !== '' ? e?.old_data['email'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{e?.new_data['email']}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['phone'] || e?.new_data['phone'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Phone</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['phone'] && e?.old_data['phone'] !== '' ? e?.old_data['phone'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>
                                                            {e?.new_data['phone'] && e?.new_data['phone'] !== '' ? e?.new_data['phone'] : "''"}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {e?.old_data['dob'] || e?.new_data['dob'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Date of birth</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['dob'] && e?.old_data['dob'] !== ''
                                                                ? moment(e?.old_data['dob']).format('DD-MMM-YYYY')
                                                                : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>
                                                            {e?.new_data['dob'] && e?.new_data['dob'] !== ''
                                                                ? moment(e?.new_data['dob']).format('DD-MMM-YYYY')
                                                                : "''"}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {e?.old_data['position'] || e?.new_data['position'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Position</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography className="capitalizeText" style={{ color: 'red' }}>
                                                            {e?.old_data['position'] && e?.old_data['position'] !== '' ? e?.old_data['position'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography className="capitalizeText" style={{ color: 'green' }}>
                                                            {e?.new_data['position']}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {e?.old_data['group'] || e?.new_data['group'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Group</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography className="capitalizeText" style={{ color: 'red' }}>
                                                            {e?.old_data['group'] && e?.old_data['group'] !== '' ? e?.old_data['group'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography className="capitalizeText" style={{ color: 'green' }}>
                                                            {e?.new_data['group']}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {e?.old_data['rate'] || e?.new_data['rate'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Our Rate Hour - Day</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['rate'] && e?.old_data['rate'] !== '' ? format_money(e?.old_data['rate']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{format_money(e?.new_data['rate'])}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['yearly_salary'] || e?.new_data['yearly_salary'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Yearly Salary</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['yearly_salary'] && e?.old_data['yearly_salary'] !== ''
                                                                ? format_money(e?.old_data['yearly_salary'])
                                                                : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{format_money(e?.new_data['yearly_salary'])}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['bonus'] || e?.new_data['bonus'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Yearly Fix Bonus</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['bonus'] && e?.old_data['bonus'] !== '' ? format_money(e?.old_data['bonus']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{format_money(e?.new_data['bonus'])}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['overtime_normal'] || e?.new_data['overtime_normal'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Overtime Normal</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['overtime_normal'] && e?.old_data['overtime_normal'] !== '' ? format_money(e?.old_data['overtime_normal']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{format_money(e?.new_data['overtime_normal'])}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['overtime_weekend'] || e?.new_data['overtime_weekend'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Overtime Weekend</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['overtime_weekend'] && e?.old_data['overtime_weekend'] !== '' ? format_money(e?.old_data['overtime_weekend']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{format_money(e?.new_data['overtime_weekend'])}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['overtime_holiday'] || e?.new_data['overtime_holiday'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Overtime Holiday</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['overtime_holiday'] && e?.old_data['overtime_holiday'] !== '' ? format_money(e?.old_data['overtime_holiday']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{format_money(e?.new_data['overtime_holiday'])}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['bank_number'] || e?.new_data['bank_number'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Bank Number</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['bank_number'] && e?.old_data['bank_number'] !== ''
                                                                ? e?.old_data['bank_number']
                                                                : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{e?.new_data['bank_number']}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {e?.old_data['bank_name'] || e?.new_data['bank_name'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Bank Name</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['bank_name'] && e?.old_data['bank_name'] !== '' ? e?.old_data['bank_name'] : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>{e?.new_data['bank_name']}</Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {e?.old_data['day_off'] || e?.new_data['day_off'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Day Off Exist</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['day_off'] && e?.old_data['day_off'] !== '' ? parse(e?.old_data['day_off']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>
                                                            {e?.new_data['day_off'] && e?.new_data['day_off'] !== '' ? parse(e?.new_data['day_off']) : "''"}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {e?.old_data['day_off_used'] || e?.new_data['day_off_used'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Day Off Used</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['day_off_used'] && e?.old_data['day_off_used'] !== '' ? parse(e?.old_data['day_off_used']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>
                                                            {e?.new_data['day_off_used'] && e?.new_data['day_off_used'] !== '' ? parse(e?.new_data['day_off_used']) : "''"}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {e?.old_data['note'] || e?.new_data['note'] ? (
                                                <>
                                                    <Space>
                                                        <Typography>Change</Typography>
                                                        <Typography style={{ color: '#0288d1' }}>Note</Typography>
                                                        <Typography>from</Typography>
                                                        <Typography style={{ color: 'red' }}>
                                                            {e?.old_data['note'] && e?.old_data['note'] !== '' ? parse(e?.old_data['note']) : "''"}
                                                        </Typography>
                                                        <Typography>to</Typography>
                                                        <Typography style={{ color: 'green' }}>
                                                            {e?.new_data['note'] && e?.new_data['note'] !== '' ? parse(e?.new_data['note']) : "''"}
                                                        </Typography>
                                                    </Space>
                                                    <br />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ))}
                                </Card>
                            </div>
                        ),
                    };
                })}
            />
        );
    };

    return (
        <>
            <Button type="primary" onClick={handleOpen} ghost>
                Activity
            </Button>
            <ModalFormTKG title="Activity" onClose={handleClose} open={open}>
                <Tabs defaultActiveKey="1" type="card" items={[
                    {
                        key: '1',
                        label: 'Profile',
                        children: <Card>{loading ? <Typography style={{ textAlign: 'center' }}><Spin size='large' /></Typography> : <LogTimeLine />}</Card>,
                    },
                    {
                        key: '2',
                        label: 'Yearly Salary',
                        children: <Card>{loading ? <Typography style={{ textAlign: 'center' }}><Spin size='large' /></Typography> : <LogWageTimeLine />}</Card>,
                    }
                ]} />
            </ModalFormTKG>
        </>
    );
};

export default HistoryUser;
