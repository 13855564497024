import React from 'react'
import { ModalTKG } from 'tkg-composite-ui';
import { Row, Col, Typography, Card, Tag } from "antd"
import { RightSquareOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SessionStorageService } from '../../api';
import { redirectPage } from '../../pages/dashboard';
const { Title } = Typography;

const CardStyle = styled(Card)`
    transition: 0.3s all;
    border: 1px solid #dae1e7;
    &:hover {
        background: #3085d614;
        border-color: #3c8dbc6b !important;
        .ant-card-head {
            background: #62a8ea21;
        }
    }
`;
const Web2BizWebsModal = (props) => {
    const { open, onClose, web2bizWebsites } = props
    return (

        <ModalTKG
            title="TKG Subcription"
            footer={false}
            onClose={onClose}
            open={open}
        >
            <Row gutter={[16, 16]}>
                {web2bizWebsites.map((userWebsite, index) => {
                    return (
                        <Col span={24} md={6} key={index} className="container-col">
                            <CardStyle
                                title="Go to"
                                bordered={false}
                                extra={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <a
                                            target={'_blank'}
                                            href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api).login}?email=${SessionStorageService.getUser()?.email
                                                }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                            rel="noreferrer"
                                        >
                                            <RightSquareOutlined className="icon-b2c" />
                                        </a>
                                    </div>
                                }
                            >
                                <a
                                    target={'_blank'}
                                    href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api).login}?email=${SessionStorageService.getUser()?.email
                                        }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                    rel="noreferrer"
                                >
                                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                        {userWebsite.website.name}
                                    </Title>
                                </a>
                            </CardStyle>
                        </Col>
                    );
                })}
            </Row>
        </ModalTKG>
    )
}

export default Web2BizWebsModal