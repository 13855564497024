/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Col, Row, Avatar, Pagination, Spin, Button, Switch, Tag, Card, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './dashboard.scss';
import './manageUser.scss';
import { useNavigate } from 'react-router';
import { SessionStorageService, Constant, isLoginSession, getListManageUser, updateUser, detailUser } from '../api';
import { PopupStatusTKG } from 'tkg-composite-ui';
import ModalAddProfile from '../components/user/modalAddProfile';

const { Search } = Input;

const ManageUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    const checkUser = async () => {
        await detailUser(user?.id).then((response) => {
            if (response?.data?.meta?.status === 200) {
                if (response?.data?.response?.role_id !== 1) {
                    navigate('/');
                }
            }
        });
    };

    const getListUsers = async () => {
        setLoading(true);

        const dataPage = {
            page: page,
            per_page: 12,
        };
        const result = await getListManageUser({ ...dataPage, search: searchText });
        setListData(result?.data?.response);
        setLoading(false);
    };
    const onChangePage = (page) => {
        setPage(page);
    };

    const handleChangeUser = (e) => {
        setSearchText(e);
    };

    useEffect(() => {
        if (isLoginSession() === false) {
            return navigate(Constant.Pages.Login);
        }
        checkUser();
        getListUsers();
    }, []);

    useEffect(() => {
        checkUser();
        getListUsers();
    }, [page, searchText]);

    const onChange = async (checked, id) => {
        setLoading(true);

        await updateUser(+id, { active: checked ? 1 : 0 }).then((res) => {
            if (res?.data?.meta?.status === 200) {
                PopupStatusTKG(
                    'success',
                    'Set User from ' + (checked ? "'Deactive'" : "'Active'") + ' to ' + (checked ? "'Active'" : "'Deactive'") + ' Successfully!',
                    false,
                    4000,
                );
                getListUsers();
            }
        });
    };

    const handleViewUser = (id) => {
        navigate(`/profile/${id}`);
    };

    return (
        <div className="container" style={{ marginTop: 50 }}>
            <Spin spinning={loading}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {SessionStorageService.getUser()?.role?.id === 1 ? (
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12} lg={12}>
                                    <ModalAddProfile />
                                </Col>
                                <Col xs={24} md={12} lg={12} align="right">
                                    <Search onSearch={handleChangeUser} enterButton="Search" size="large" loading={loading} style={{ width: '50%' }} />
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}
                    </Col>
                    {listData?.data?.map((item) => {
                        return (
                            <Col className="card-user" span={24} sm={12} md={12} lg={8} key={item.id}>
                                <Card
                                    className={item?.active === 0 ? 'card-user-disabled' : 'card-user-active'}
                                    style={{ padding: 0 }}
                                    actions={[
                                        <Button type="text" size="small" onClick={() => handleViewUser(item.id)}>
                                            View <ArrowRightOutlined />
                                        </Button>,
                                        <Switch
                                            checked={item.active === 1}
                                            checkedChildren="Active"
                                            unCheckedChildren="Deactive"
                                            onChange={(checked) => onChange(checked, item.id)}
                                        />,
                                    ]}
                                >
                                    <Row align="middle" gutter={[16, 20]}>
                                        <Col span={8} md={8} lg={7} xs={9}>
                                            <Avatar
                                                style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
                                                size={{ xs: 80, sm: 60, md: 60, lg: 60, xl: 60, xxl: 80 }}
                                            >
                                                {item.first_name}
                                            </Avatar>
                                        </Col>
                                        <Col span={16} md={16} lg={17} xs={15}>
                                            <p>
                                                {item.first_name} {item.surname}
                                            </p>
                                            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.email}</p>
                                            <Tag color={item.role.id === 1 ? 'red' : 'green'}>{item.role.name}</Tag>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>

                <Row justify="center">
                    <Pagination
                        current={page}
                        pageSize={12}
                        total={listData?.total}
                        onChange={onChangePage}
                        // showTotal={(total) => `Total User : ${total}`}
                        style={{ marginTop: 35 }}
                    />
                </Row>
            </Spin>
        </div>
    );
};

export default ManageUser;
