import axios from 'axios';
import { SessionStorageService } from './sessionStorageService';
import { Constant } from './constant';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}api`,
    timeout: 300000,
});

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    (error) => {
        if (error.response.data.meta.status === 401 && error.response.data.meta.message === 'Token Expired !') {
            const config = error.response.config || {};
            SessionStorageService.setAuthData(error.response.data.response.token);
            sessionStorage.setItem('lifeTimeToken', process.env.REACT_APP_LIFE_TIME_TOKEN);
            sessionStorage.setItem('showConfirmLogout', false);
            const iframe = document.querySelectorAll('.iframe-web');
            iframe.forEach((item) => {
                item.contentWindow.postMessage({ type: 'refresh-token', token: error.response.data.response.token }, item.getAttribute('src'));
            });
            config.headers.Authorization = 'Bearer ' + SessionStorageService.getAuthData();
            console.log('refresh user token 401 && Token Expired', config);
            return instance(config);
        } else if (error.response.data.meta.status === 401) {
            console.log('refresh user token 401');
            localStorage.removeItem("accessToken")
            // SessionStorageService.removeSessionData();
            // eslint-disable-next-line no-restricted-globals
            location.href = Constant.Pages.Login;
            return;
        }
        return error;
    },
);

export async function getAuthPure(url, data = {}) {
    const result = await axios.get(url, { headers: SessionStorageService.getAuthHeader(), params: data });
    return result;
}
export async function postAuthPure(url, data) {
    const result = await axios.post(url, data, { headers: SessionStorageService.getAuthHeader() });
    return result;
}

export async function putAuthPure(url, data) {
    const result = await axios.put(url, data, { headers: SessionStorageService.getAuthHeader() });
    return result;
}

export async function deleteAuthPure(url) {
    const result = await axios.delete(url, { headers: SessionStorageService.getAuthHeader() });
    return result;
}
export async function getAuth(url, data = {}) {
    const result = await instance.get(url, { headers: SessionStorageService.getAuthHeader(), params: data });
    return result;
}

export async function postAuth(url, data) {
    const result = await instance.post(url, data, { headers: SessionStorageService.getAuthHeader() });
    return result;
}

export async function putAuth(url, data) {
    const result = await instance.put(url, data, { headers: SessionStorageService.getAuthHeader() });
    return result;
}

export async function deleteAuth(url) {
    const result = await instance.delete(url, { headers: SessionStorageService.getAuthHeader() });
    return result;
}
