import { RightSquareOutlined } from '@ant-design/icons';
import { Col, Row, Card, Typography, Tag } from 'antd';
import { SessionStorageService } from '../../api';
import styled from 'styled-components';
import { redirectPage } from '../../pages/dashboard';
import DevelopmentWebsModal from './developmentWebsModal';
import React, { useState } from 'react';
import WhiteLabelsWebsModal from './whiteLabelsWebsModal';
import TKGSubscriptionModal from './TKGSubscriptionWebsModal';
import Web2BizWebsModal from './Web2BizWebsModal';
import DashboardWebsiteModal from './dashboardWebsModal';
import WebsiteDevelopmentModal from './websiteDevelopment';

const { Title } = Typography;

const CardStyle = styled(Card)`
  transition: 0.3s all;
  border: 1px solid #dae1e7;

  &:hover {
    background: #3085d614;
    border-color: #3c8dbc6b !important;

    .ant-card-head {
      background: #62a8ea21;
    }
  }
`;

const AccessPortal = ({ websites, userRole, token }) => {
    const [isOpenDevWebsModal, setIsOpenDevWebsModal] = useState(false);
    const [isOpenDashboardModal, setIsOpenDashboardModal] = useState(false);
    const [isOpenWhiteLabelsModal, setIsOpenWhiteLabelsModal] = useState(false);
    const [isOpenTKGSubscriptionModal, setIsOpenTKGSubscriptionModal] = useState(false);
    const [isOpenWeb2BizModal, setIsOpenWeb2BizModal] = useState(false);
    const [isOpenWebsiteModal, setIsOpenWebsiteModal] = useState(false);
    const portals = [
        { name: 'TCS Portal', link: '' },
        { name: 'Web2Biz', link: '' },
        { name: 'Master TKG', link: '' },
        { name: 'TKG Subscription', link: '' },
        { name: 'APIs Platform', link: '' },
        { name: 'White Labels', link: '' },
        { name: 'Apps Development', link: '' },
        { name: 'Website templates', link: '' },
        { name: 'Ui Documents', link: 'https://uidoc.tkgplatform.com.au/' },
    ];

    const productWebsites = websites.filter((item, index) => item.website.environment === 'production' && !Object.values(JSON.parse(item.website?.tags) || {})?.includes('dashboard') && !Object.values(JSON.parse(item.website?.tags) || {})?.includes('white_lable') && !Object.values(JSON.parse(item.website?.tags) || {})?.includes('subscription') && !Object.values(JSON.parse(item.website?.tags) || {})?.includes('web2biz'));
    const developmentWebsites = websites.filter(item => item.website.environment === 'development' && !Object.values(JSON.parse(item.website?.tags || {})).includes('b2c'));
    const whiteLabelsWebsites = websites.filter(item => Object.values(JSON.parse(item.website?.tags) || {})?.includes('white_lable'));
    const subcriptionWebsites = websites.filter(item => Object.values(JSON.parse(item.website?.tags) || {})?.includes('subscription'));
    const web2bizWebsites = websites.filter(item => Object.values(JSON.parse(item.website?.tags) || {})?.includes('web2biz'));
    const dashboardWebsite = websites.filter(item => Object.values(JSON.parse(item.website?.tags) || {})?.includes('dashboard') );
    const websiteDevelopment = websites.filter(item => Object.values(JSON.parse(item.website?.tags) || {})?.includes('b2c') &&  !Object.values(JSON.parse(item.website?.tags) || {})?.includes('dashboard') && item.website.environment === 'development');

    return (

        <> <Row gutter={[16, 16]}>
            <Col span={24} md={6} className='container-col'>
                <CardStyle title='Go to' bordered={false} extra={<RightSquareOutlined className='icon-b2c' />}
                           onClick={() => setIsOpenDevWebsModal(true)}>
                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                        TKG Development
                    </Title>
                </CardStyle>
            </Col>
            <Col span={24} md={6} className='container-col'>
                <CardStyle title='Go to' bordered={false} extra={<RightSquareOutlined className='icon-b2c' />}
                           onClick={() => setIsOpenDashboardModal(true)}>
                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                        Websites Dashboard
                    </Title>
                </CardStyle>
            </Col>
            <Col span={24} md={6} className='container-col'>
                <CardStyle title='Go to' bordered={false} extra={<RightSquareOutlined className='icon-b2c' />}
                           onClick={() => setIsOpenWebsiteModal(true)}>
                    <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                        Websites Development
                    </Title>
                </CardStyle>
            </Col>
            <Col span={24} md={6} className='container-col'>
                <CardStyle
                    title='Go to'
                    bordered={false}
                    extra={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tag color='cyan' style={{ marginRight: 20 }}>
                                TRAINING
                            </Tag>
                            <a onClick={() => redirectPage(process.env.REACT_APP_ENVIRONTMENT === 'development' ? `${process.env.REACT_APP_DOMAIN_TRAINING_MATERIAL_DEV}verify/` : `${process.env.REACT_APP_DOMAIN_TRAINING_MATERIAL_PRODUCT}verify/`)}
                               target='_blank'>
                                <RightSquareOutlined className='icon-b2c' />
                            </a>
                        </div>
                    }
                >
                    <a onClick={() => redirectPage(process.env.REACT_APP_ENVIRONTMENT === 'development' ? `${process.env.REACT_APP_DOMAIN_TRAINING_MATERIAL_DEV}verify/` : `${process.env.REACT_APP_DOMAIN_TRAINING_MATERIAL_PRODUCT}verify/`)}
                       target='_blank'>
                        <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                            Training Material
                        </Title>
                    </a>
                </CardStyle>
            </Col>


            {productWebsites.map((userWebsite, index) => {
                return (
                    <Col span={24} md={6} key={index} className='container-col'>
                        <CardStyle
                            title='Go to'
                            bordered={false}
                            extra={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {userWebsite.website.environment != 'production' ? (
                                        <Tag color='volcano' style={{ marginRight: 20 }}>
                                            DEVELOPMENT
                                        </Tag>
                                    ) : (
                                        ''
                                    )}
                                    {JSON.parse(userWebsite.website.endpoint_api)?.login ?( JSON.parse(userWebsite.website.tags).includes("verify") ? <a
                                        target={'_blank'}
                                        href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}/${token}`}
                                        rel="noreferrer"
                                    >
                                        <RightSquareOutlined className="icon-b2c" />
                                    </a> :  <a
                                        target={'_blank'}
                                        href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}?email=${SessionStorageService.getUser()?.email
                                        }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                        rel="noreferrer"
                                    >
                                        <RightSquareOutlined className="icon-b2c" />
                                    </a>)   : <a
                                        target={'_blank'}
                                        href={`${userWebsite.website.domain}`}
                                        rel="noreferrer"
                                    >
                                        <RightSquareOutlined className="icon-b2c" />
                                    </a>}

                                </div>
                            }

                        >
                            {JSON.parse(userWebsite.website.endpoint_api)?.login ? <a
                                target={'_blank'}
                                href={`${userWebsite.website.domain}${JSON.parse(userWebsite.website.endpoint_api)?.login}?email=${SessionStorageService.getUser()?.email
                                }&secret_key=${SessionStorageService.getUser()?.secret_key}`}
                                rel="noreferrer"
                            >
                                <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                    {userWebsite.website.name}
                                </Title>
                            </a>  : <a
                                target={'_blank'}
                                href={`${userWebsite.website.domain}`}
                                rel="noreferrer"
                            >
                                <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                                    {userWebsite.website.name}
                                </Title>
                            </a>}

                        </CardStyle>
                    </Col>
                );
            })}

            {portals.map((portal, index) => (
                <Col key={index} span={24} md={6} className='container-col'>
                    <CardStyle title='Go to' bordered={false}
                               extra={(portal.name === 'White Labels' || portal.name === 'TKG Subscription' || portal.name === 'Web2Biz' || !portal.link) ?
                                   <RightSquareOutlined className='icon-b2c' /> :
                                   <div style={{ display: 'flex', alignItems: 'center' }}>

                                       <a
                                           target={'_blank'}
                                           href={portal.link}
                                           rel='noreferrer'
                                       >
                                           <RightSquareOutlined className='icon-b2c' />
                                       </a>
                                   </div>
                               } onClick={() => {
                        if (portal.name === 'White Labels' && whiteLabelsWebsites.length > 0) {
                            setIsOpenWhiteLabelsModal(true);
                        } else if (portal.name === 'TKG Subscription') {
                            setIsOpenTKGSubscriptionModal(true);
                        } else if (portal.name === 'Web2Biz') {
                            setIsOpenWeb2BizModal(true);
                        }
                    }}>
                        <Title level={3} style={{ margin: 0, paddingBottom: 20 }}>
                            {portal.name}
                        </Title>
                    </CardStyle>
                </Col>
            ))}
        </Row>
            {
                isOpenDevWebsModal &&
                <DevelopmentWebsModal open={isOpenDevWebsModal} onClose={() => setIsOpenDevWebsModal(false)}
                                      developmentWebs={developmentWebsites} />
            }
            {
                isOpenWhiteLabelsModal &&
                <WhiteLabelsWebsModal open={isOpenWhiteLabelsModal} onClose={() => setIsOpenWhiteLabelsModal(false)}
                                      whiteLabelsWebs={whiteLabelsWebsites} />
            }
            {
                isOpenTKGSubscriptionModal && <TKGSubscriptionModal open={isOpenTKGSubscriptionModal}
                                                                    onClose={() => setIsOpenTKGSubscriptionModal(false)}
                                                                    subcriptionWebsites={subcriptionWebsites} />
            }
            {
                isOpenWeb2BizModal &&
                <Web2BizWebsModal open={isOpenWeb2BizModal} onClose={() => setIsOpenWeb2BizModal(false)}
                                  web2bizWebsites={web2bizWebsites} />
            }
            {
                isOpenDashboardModal &&
                <DashboardWebsiteModal open={isOpenDashboardModal} onClose={() => setIsOpenDashboardModal(false)}
                                       dashboardWebsite={dashboardWebsite} />
            }
            {
                isOpenWebsiteModal && <WebsiteDevelopmentModal open={isOpenWebsiteModal} onClose={() => setIsOpenWebsiteModal(false)}
                                                            websiteDevelopment={websiteDevelopment} />
            }
        </>
    );
};

export default AccessPortal;
