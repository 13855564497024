import * as ACTION_TYPES from '../actions/actionType';

export const initialState = {
    isOpenModal: null,
};

export const TimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SHOWMODAL:
            return {
                ...state,
                isOpenModal: true,
            };
        case ACTION_TYPES.HIDEMODAL:
            return {
                ...state,
                isOpenModal: false,
            };
        default:
            return state;
    }
};
