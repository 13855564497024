import React, { useState } from 'react';
import { putChangeRoleUser, Constant } from '../../api';
import { SelectTKG, PopupStatusTKG } from 'tkg-composite-ui';
import './updateRole.scss';

function UpdateRole({ id, roleId, roleList, callback }) {
    const [disabled, setDisabled] = useState(false);
    const handleChangeRole = async (event) => {
        setDisabled(true);
        const result = await putChangeRoleUser(id, event);
        setDisabled(false);
        if (result?.data?.statusCode === 200) {
            PopupStatusTKG(Constant.PopupAlertTypes.Success, result.data.message);
            callback();
        }
    };

    return (
        <SelectTKG value={roleId} options={roleList} fieldNames={{ label: 'name', value: 'id' }} onChange={handleChangeRole} disabled={disabled}></SelectTKG>
    );
}
export default UpdateRole;
