import { getAuth } from '../authService';
const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    MANAGE_USER: 'api/user',
};

export const getListManageUser = async (params) => {
    return await getAuth(API_URL + endPoint.MANAGE_USER, params);
};
