/* eslint-disable no-unused-vars */
import { Form, Checkbox, Button, Modal } from 'antd';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { postCreateRole, putUpdateRole, Constant } from '../../api';
import { InputTKG, InputNumberTKG, PopupStatusTKG, TableTKG } from 'tkg-composite-ui';
import './modalAdd.scss';

const ModalAdd = ({ roleItem, menus, order, callback }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState(roleItem?.permissions ?? []);
    const [form] = Form.useForm();
    const showModal = () => {
        setIsModalOpen(true);
    };
    const hideModal = () => {
        setIsModalOpen(false);
    };

    if (!roleItem && !!menus) {
        // console.log('menus', menus);
        menus = menus.map((item) => ({
            isViewed: false,
            isUpdated: false,
            isManaged: false,
            ...item,
        }));
        // console.log('menus', menus);
    } else if (!!roleItem) {
        menus = menus.map((item) => ({
            isViewed: roleItem.permissions?.some((p) => p.menuId === item.id && p.isViewed),
            isUpdated: roleItem.permissions?.some((p) => p.menuId === item.id && p.isUpdated),
            isManaged: roleItem.permissions?.some((p) => p.menuId === item.id && p.isManaged),
            ...item,
        }));
    }

    const handleOnChange = async (event, id, fieldName) => {
        let item = selectedPermissions.find((p) => p.menuId === id);
        // console.log('item', item, event, id, fieldName);
        if (item === undefined) {
            item = { menuId: id, isViewed: false, isUpdated: false, isManaged: false };
            item[fieldName] = true;
            selectedPermissions.push(item);
        } else {
            item[fieldName] = event.target.checked;
            if (!event.target.checked) {
                if (!item.isViewed && !item.isUpdated && !item.isManaged) {
                    selectedPermissions.splice(selectedPermissions.indexOf(item), 1);
                }
            }
        }
        // console.log('selectedPermissions', selectedPermissions);
    };

    const columns = [
        { title: 'Feature', key: 'name', dataIndex: 'name', align: 'left' },
        {
            title: 'View',
            key: 'isViewed',
            dataIndex: 'isViewed',
            width: '20%',
            align: 'center',
            render: (text, record) => <Checkbox defaultChecked={record.isViewed} onChange={(event) => handleOnChange(event, record.id, 'isViewed')}></Checkbox>,
        },
        {
            title: 'Update',
            key: 'isUpdated',
            dataIndex: 'isUpdated',
            width: '20%',
            align: 'center',
            render: (text, record) => (
                <Checkbox defaultChecked={record.isUpdated} onChange={(event) => handleOnChange(event, record.id, 'isUpdated')}></Checkbox>
            ),
        },
        {
            title: 'Manage',
            key: 'isManaged',
            dataIndex: 'isManaged',
            width: '20%',
            align: 'center',
            render: (text, record) => (
                <Checkbox defaultChecked={record.isManaged} onChange={(event) => handleOnChange(event, record.id, 'isManaged')}></Checkbox>
            ),
        },
    ];

    const onClick = async (data) => {
        form.validateFields()
            .then((role) => {
                //form.resetFields();
                role.permissions = selectedPermissions;
                // console.log('validateFields role', role);
                if (!roleItem) {
                    postCreateRole(role).then(roleCallback);
                } else {
                    role.id = roleItem.id;
                    putUpdateRole(role).then(roleCallback);
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const roleCallback = (result) => {
        if (result?.data?.statusCode === 200) {
            PopupStatusTKG(Constant.PopupAlertTypes.Success, result.data.message);
            form.resetFields();
            hideModal();
            callback();
        } else {
            PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.message);
        }
    };

    return (
        <div className="add-role">
            {!roleItem ? (
                <Button onClick={showModal} className="btnTKGAction modal-add-button" icon={<PlusCircleOutlined />}>
                    Add New
                </Button>
            ) : (
                <Button onClick={showModal} className="btnTKGAction modal-add-button" shape="circle" icon={<EditOutlined />}></Button>
            )}

            <Modal
                className="modal-add-role"
                title="Role"
                open={isModalOpen}
                onCancel={hideModal}
                footer={[
                    <Button key="submit" className="btnTKGAction" type="primary" htmlType="submit" onClick={onClick}>
                        Save
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    name="modal-add-role"
                    layout="vertical"
                    initialValues={{
                        name: roleItem?.name,
                        order: roleItem?.order ?? order,
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the name',
                            },
                        ]}
                    >
                        <InputTKG form={form} />
                    </Form.Item>
                    <Form.Item name="order" label="Order" style={{ display: 'inline-block', width: 'calc(50% - 1rem)', fontWeight: 600, margin: '0 1rem' }}>
                        <InputNumberTKG form={form} />
                    </Form.Item>
                    <Form.Item name="permissions" label="Permission">
                        <TableTKG columns={columns} dataSource={menus} rowKey="id" isShowedFullData={true} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalAdd;
