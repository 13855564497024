export const Constant = {
    Pages: {
        Login: '/login',
        ForgotPassword: '/forget-password/:token',
        Dashboard: '/',
        User: '/user',
        Role: '/role',
        Signout: '/signout',
        Register: '/register',
        VerifyAccount: '/verify-account/:token',
        Verify: '/verify/:token',
        ChangePassword: '/change-password',
        ManageUser: '/employee-manage',
        Profile: '/profile/:id',
    },
    PopupAlertTypes: {
        Success: 'success',
        Error: 'error',
        Warning: 'warning',
        Info: 'info',
        Question: 'question',
    },
    ButtonDeleteTKGMessage: {
        User: 'Are you sure you want to delete this user?',
        Role: 'Are you sure you want to delete this role?',
    },
    SwitchActiveTKGMessage: {
        ActiveUser: 'Are you sure you want to active user?',
        DeactiveUser: 'Are you sure you want to deactive user?',
    },
    NotificationTypes: {
        Success: 'success',
        Error: 'error',
    },

    Currency: [
        {
            value: 'AUD',
            label: 'AUD',
        },
        {
            value: 'USD',
            label: 'USD',
        },
        {
            value: 'VND',
            label: 'VND',
        },
    ],
};
