/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Typography } from 'antd';
import FormLogin from '../components/login/formLogin';
import FormForgotPassword from '../components/forgotPassword/formForgotPassword';
import { isLoginSession, Constant } from '../api';
import { useNavigate } from 'react-router-dom';
import './login.scss';
import Logo from '../assets/images/logos/default-avatar.jpg';

const { Title } = Typography;

const Login = (props) => {
    const navigate = useNavigate();
    React.useEffect(() => {
        if (isLoginSession() === true) {
            return navigate(Constant.Pages.Dashboard);
        }
    }, []);

    return (
        <>
            <Row className="container-login" align="middle" justify="center">
                <Col className="container-layout-col" span={24} md={20} lg={16}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col className="container-login-form" sm={16} md={20} lg={24}>
                            <div className="logo">
                                <img className="logo" alt="Logo" src={Logo} width={180} />
                            </div>
                            <div className="block-title">
                                <Title className="titleLogin text-style-shadow">TWEET WORLD TRAVEL GROUP</Title>
                                <Title className="titleLoginSub text-style-shadow" level={2}>
                                    beyond your dreams
                                </Title>
                            </div>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col className="container-login-form" sm={16} md={12} lg={11}>
                            {props.isLogin ? <FormLogin /> : <FormForgotPassword />}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
export default Login;
