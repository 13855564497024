import { getAuth, postAuth, putAuth, deleteAuth } from '../authService';
import { SessionStorageService } from '../sessionStorageService';

const endPoint = {
    ROLE: 'Role/Get/',
    ROLE_ADD: 'Role/Add',
    ROLE_UPDATE: 'Role/Update',
    ROLE_DELETE: 'Role/Delete/',
};

export const getListRole = async () => {
    const itemsUser = SessionStorageService.getUser();
    return await getAuth(endPoint.ROLE + itemsUser.companyId);
};

export const postCreateRole = async (data) => {
    updateAddDataRequest(data);
    return await postAuth(endPoint.ROLE_ADD, data);
};

export const putUpdateRole = async (data) => {
    updateEditDataRequest(data);
    return await putAuth(endPoint.ROLE_UPDATE, data);
};

export const deleteRole = async (id) => {
    const itemsUser = SessionStorageService.getUser();
    return await deleteAuth(endPoint.ROLE_DELETE + id + '/' + itemsUser.id);
};

const updateAddDataRequest = function (data) {
    const itemsUser = SessionStorageService.getUser();
    data.companyId = itemsUser.companyId;
    data.createdBy = itemsUser.id;
};

const updateEditDataRequest = function (data) {
    const itemsUser = SessionStorageService.getUser();
    data.companyId = itemsUser.companyId;
    data.updatedBy = itemsUser.id;
};
