import { getAuth } from '../authService';

const endPoint = {
    LOGIN_HISTORY: '/user/history/login',
};

export const getListLoginHistory = async (params) => {
    const dataRes = await getAuth(endPoint.LOGIN_HISTORY, params);
    return dataRes;
};
