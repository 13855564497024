import * as ACTION_TYPES from "../actions/actionType";

export const showModal = () => {
    return {
      type: ACTION_TYPES.SHOWMODAL,
    };
  };
  
  export const hideModal = () => {
    return {
      type: ACTION_TYPES.HIDEMODAL,
    };
  };