/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Typography, Row, Col } from 'antd';
import {
    postSendLoginCode,
    postLogin,
    Constant,
    getCompanyCodeLocalStorage,
    getEmailLocalStorage,
    setSessionData,
    checkUserVerify,
    postReSendMail,
} from '../../api';
import { InputTKG, PopupStatusTKG } from 'tkg-composite-ui';
import { DoubleRightOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import OtpInput from 'react18-input-otp';
import './formLogin.scss';
import { PopupCustomConfirmButtonTKG } from '../function/PopupBackLoginTKG';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

function FormLogin() {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [nextToCode, setNextToCode] = useState(false);
    const [lockedAccount, setLockedAccount] = useState(false);
    const [otp, setOTP] = useState('');
    const handleChangeOTP = (e) => setOTP(e);
    const clearOTP = () => setOTP('');
    const [userLogin, setUserLogin] = useState(false);
    const [isLoadingSendCode, setIsLoadingSendCode] = useState(false);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [emailUser, setEmailUser] = useState('');

    const styleOTP = {
        width: '100%',
        padding: '20px',
        font: 'inherit',
        border: '1px solid #d2d6da',
        fontSize: '0.875rem',
        borderRadius: '0.375rem',
        margin: '10px 0',
    };

    const onFinishSendLoginCode = async (data) => {
        setIsLoadingSendCode(true);
        setEmailUser(data.email);
        let resultCheck = await checkUserVerify(data.email);
        if (resultCheck?.data.meta?.status === 200) {
            let result = await postSendLoginCode(data);
            if (result?.data.meta?.status === 200) {
                setUserLogin(data);
                PopupStatusTKG(Constant.PopupAlertTypes.Success, result.data.response);
                setNextToCode(true);
            } else {
                if (result.data.response.length !== 0) {
                    let reason = '';
                    Object.keys(result?.data.response).forEach((element) => {
                        reason += `\n${result?.data.response[element]}`;
                    });
                    PopupCustomConfirmButtonTKG('error', 'OK', reason, () => { });
                } else {
                    PopupCustomConfirmButtonTKG('error', 'OK', result?.data.meta?.message, () => { });
                }
            }
        } else {
            if (resultCheck?.data.meta?.status === 404) {
                PopupStatusTKG(Constant.PopupAlertTypes.Error, resultCheck?.data.meta?.message);
            }
            if (resultCheck?.data.meta?.status === 422) PopupCustomConfirmButtonTKG('error', 'Verify Now', 'Email is not verify yet', onResentEmail);
        }
        setIsLoadingSendCode(false);
    };

    const onResentEmail = async () => {
        const data = { email: getEmailLocalStorage() };
        const result = await postReSendMail(data);
        if (result?.data.meta?.status === 200) {
            PopupStatusTKG(Constant.Pages.Success, 'Sent done! Please check your email');
        } else {
            if (Object.keys(result.data.response).length === 0 && result.data.response.constructor === Object) {
                PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.meta?.message);
            } else {
                let reason = '';
                Object.keys(result?.data.response).forEach((element) => {
                    reason += `\n${result?.data.response[element]}`;
                });
                PopupStatusTKG(Constant.PopupAlertTypes.Error, reason);
            }
        }
    };

    const onLogin = async (e) => {
        e.preventDefault();
        if (otp.length === 5) {
            var data = Object.assign({}, userLogin);
            data.verify_code = otp;

            setIsLoadingLogin(true);
            var result = await postLogin(data);
            setIsLoadingLogin(false);

            if (result?.data?.meta.status === 200) {
                let response = result.data.response;
                setSessionData({
                    accessToken: response.access_token,
                    user: response.user,
                });
                sessionStorage.setItem('lifeTimeToken', process.env.REACT_APP_LIFE_TIME_TOKEN);
                sessionStorage.setItem('showConfirmLogout', false);
                navigate(Constant.Pages.Dashboard);


                console.log(response.user, "1345");
                signInWithEmailAndPassword(auth, response.user.email ?? "", process.env.REACT_APP_SECRET_PASSWORD)
            } else {
                PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.meta.message);
            }
        } else {
            PopupStatusTKG(Constant.PopupAlertTypes.Error, 'Verification of code must be 5 characters');
        }
    };

    const getNewCode = async (e) => {
        const data = Object.assign({}, userLogin);
        data.IsResentCode = true;
        const result = await postSendLoginCode(data);
        if (result?.data.meta?.status === 200) {
            return PopupStatusTKG(Constant.PopupAlertTypes.Success, result?.data.response);
        }
        return PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.meta?.message);
    };

    return (
        <>
            {!nextToCode ? (
                <Form
                    form={form}
                    name="login"
                    layout="vertical"
                    onFinish={onFinishSendLoginCode}
                    initialValues={{
                        companyCode: getCompanyCodeLocalStorage(),
                        email: getEmailLocalStorage(),
                    }}
                >
                    <Typography className="tittle-login-form">Login</Typography>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please use your email address',
                            },
                            {
                                required: true,
                                message: 'Please enter the email',
                            },
                        ]}
                    >
                        <InputTKG form={form} maxLength={80} placeholder="Enter your email" prefix={<UserOutlined className="site-form-item-icon" />} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the password',
                            },
                        ]}
                    >
                        <InputTKG
                            isPasswordField={true}
                            form={form}
                            placeholder="Enter your password"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            onPressEnter={() => { form.submit() }}
                        />
                    </Form.Item>
                    <Row justify="space-between">
                        <Col className="container-button">
                            <Button onClick={() => navigate(Constant.Pages.ChangePassword)} type="text" style={{ color: '#fff' }}>
                                FORGOT PASSWORD
                            </Button>
                        </Col>
                        <Col className="container-button">
                            <Button onClick={() => navigate(Constant.Pages.Register)} type="text" style={{ color: '#fff' }}>
                                Registration
                            </Button>
                        </Col>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: '1rem' }}>
                        <Col className="container-button"></Col>
                        <Col className="container-button">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btnTKGAction"
                                disabled={lockedAccount}
                                icon={<DoubleRightOutlined />}
                                loading={isLoadingSendCode}
                                style={{ backgroundColor: 'yellow', color: 'black', fontWeight: 'bold' }}
                            >
                                NEXT
                            </Button>
                        </Col>
                    </Row>
                </Form>
            ) : (
                <div className="login-code">
                    <Row>
                        <Col sm={16} md={24}>
                            <label className="label-code">Please enter the code</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={16} md={24} className="otp-code">
                            <OtpInput value={otp} onChange={handleChangeOTP} numInputs={5} separator={<span>&nbsp;</span>} inputStyle={styleOTP} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={16} md={10} lg={10} className="container-button">
                            <Button onClick={clearOTP} type="text" style={{ color: '#fff' }}>
                                Clear Code
                            </Button>
                        </Col>
                        <Col sm={16} md={14} lg={14} className="container-button-right">
                            <Button type="primary" className="btnTKGAction" icon={<DoubleRightOutlined />} onClick={onLogin} loading={isLoadingLogin}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={16} md={24} className="container-link">
                            If you haven't received,{' '}
                            <Button type="link" onClick={getNewCode}>
                                get new code
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
}

export default FormLogin;
