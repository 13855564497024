import Swal from 'sweetalert2';

export const PopupCustomConfirmButtonTKG = (type, textButton, message, functionConfirm,functionCancel=() => {}, cancelButton=false, title) => {
    Swal.fire({
        icon: type,
        title: title ?? null,
        text: message,
        focusConfirm: false,
        showCancelButton: cancelButton,
        confirmButtonText: textButton,
      }).then((result) => {
        if (result.isConfirmed) {
          functionConfirm()
        }else {
          functionCancel()
        }
      })
}
