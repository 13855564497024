import React from 'react';
import { Form, Button, Typography, Row, Col } from 'antd';
import { useState } from 'react';
import { postCheckToken, putUpdatePassword, Constant } from '../../api';
import { InputTKG, PopupStatusTKG } from 'tkg-composite-ui';
import { useParams, useNavigate } from 'react-router-dom';
import './formForgotPassword.scss';
import { PopupCustomConfirmButtonTKG } from '../function/PopupBackLoginTKG';

const { Title, Paragraph } = Typography;

const FormForgotPassword = () => {
    const [nextToLogin, setNextToLogin] = useState(false);
    const { token } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    React.useEffect(() => {
        async function checkToken() {
            var result = await postCheckToken(token);
            if (result?.data.meta?.status !== 200) {
                if (Object.keys(result.data.response).length === 0 && result.data.response.constructor === Object) {
                    let reason = '';
                    Object.keys(result?.data.response).forEach((element) => {
                        reason += `\n${result?.data.response[element]}`;
                    });
                    PopupStatusTKG(Constant.PopupAlertTypes.Error, reason, true, 10000);
                } else {
                    PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.meta?.message, true, 10000);
                }
                return navigate('/login');
            }
        }
        checkToken();
    }, [navigate, token]);

    const onFinish = async (data) => {
        setIsLoading(true);
        data = { email: localStorage.getItem('email'), password: data.password, password_confirmation: data.passwordConfirm, token: token };
        var result = await putUpdatePassword(data);
        setIsLoading(false);
        if (result?.data.meta?.status === 200) {
            setNextToLogin(true);
        } else {
            if (result.data.response.length !== 0) {
                let reason = '';
                Object.keys(result?.data.response).forEach((element) => {
                    reason += `\n${result?.data.response[element]}`;
                });
                PopupStatusTKG(Constant.PopupAlertTypes.Error, reason);
            } else {
                PopupStatusTKG(Constant.PopupAlertTypes.Error, result?.data.meta?.message);
            }
        }
    };

    const onBackLogin = () => {
        navigate('/login');
    };

    return (
        <>
            {!nextToLogin ? (
                <Form form={form} name="forgotPassword" layout="vertical" onFinish={onFinish}>
                    <Typography className="tittle-login-form">Reset Your Password</Typography>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the password',
                            },
                            {
                                validator(_, value) {
                                    if (value.length < 6 || value.length > 30) {
                                        return Promise.reject(new Error('The password has at least 6 characters long, and maximum 30 characters'));
                                    }
                                    if (!/[A-Z]/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least one uppercase letter'));
                                    }
                                    if (!/[a-z]/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least one lowercase letter'));
                                    }
                                    if (!/\d{2}/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least 2 numbers'));
                                    }
                                    if (!/[^\w]/.test(value)) {
                                        return Promise.reject(new Error('The password contains at least one special character'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputTKG isPasswordField={true} />
                    </Form.Item>
                    <Form.Item
                        name="passwordConfirm"
                        label="Password Confirm"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm the password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <InputTKG isPasswordField={true} />
                    </Form.Item>
                    <Row>
                        <Col sm={16} md={24} lg={10} className="container-button">
                            <Button type="primary" htmlType="submit" className="btnTKGAction" loading={isLoading}>
                                Submit
                            </Button>
                        </Col>
                        <Col sm={16} md={24} lg={14} className="container-button container-button-right">
                            <Button ghost href={'/login'} style={{ color: '#fff' }}>
                                Back to login
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={16} md={24}>
                            <Title level={5} style={{ color: 'white' }}>
                                Passwords must:
                            </Title>
                            <Paragraph>
                                <ul className="password-guideline">
                                    <li>+ Be at least 6 characters long, and maximum 30 characters</li>
                                    <li>+ Contain at least one uppercase letter</li>
                                    <li>+ Contain at least one lowercase letter</li>
                                    <li>+ Contain at least 2 numbers</li>
                                    <li>+ Contain at least one special character</li>
                                    <li>+ Enter and Confirm passwords match</li>
                                </ul>
                            </Paragraph>
                        </Col>
                    </Row>
                </Form>
            ) : (
                PopupCustomConfirmButtonTKG('success', 'BACK TO LOGIN', 'Your password has been changed successfully!', onBackLogin)
            )}
        </>
    );
};
export default FormForgotPassword;
