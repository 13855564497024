import { getAuth, postAuth, putAuth, deleteAuth } from '../authService';
import { SessionStorageService } from '../sessionStorageService';

const endPoint = {
    USER: 'User/Get/',
    USER_ADD: 'User/Add',
    USER_UPDATE_STATUS: 'User/UpdateStatus',
    USER_UPDATE_ROLE: 'User/UpdateRole',
    USER_DELETE: 'User/Delete/',
    USER_DETAIL: 'user/detail/',
    USER_STORE: 'user/store',
    USER_UPDATE: 'user/update/',
    LIST_USER: 'user/list-all',
    LIST_ROLES: 'user/list-roles',
    LIST_HISTORY: 'user/list-history/',
};

export const getListUser = async () => {
    const itemsUser = SessionStorageService.getUser();
    return await getAuth(endPoint.USER + itemsUser.companyId);
};

export const postCreateUser = async (data) => {
    updateAddDataRequest(data);
    return await postAuth(endPoint.USER_ADD, data);
};

export const putStatusUser = async (data) => {
    updateEditDataRequest(data);
    return await putAuth(endPoint.USER_UPDATE_STATUS, data);
};

export const putChangeRoleUser = async (id, role) => {
    let data = { id: id, roleId: role };
    updateEditDataRequest(data);
    return await putAuth(endPoint.USER_UPDATE_ROLE, data);
};

export const deleteUser = async (id) => {
    const itemsUser = SessionStorageService.getUser();
    return await deleteAuth(endPoint.USER_DELETE + id + '/' + itemsUser.id);
};

export const listUser = async () => {
    return await getAuth(endPoint.LIST_USER);
};

export const getListHistory = async (id) => {
    return await getAuth(endPoint.LIST_HISTORY + id);
};

export const listRoles = async () => {
    return await getAuth(endPoint.LIST_ROLES);
};

export const detailUser = async (id) => {
    return await getAuth(endPoint.USER_DETAIL + id);
};

export const storeUser = async (data) => {
    return await postAuth(endPoint.USER_STORE, data);
};

export const updateUser = async (id, data) => {
    return await putAuth(endPoint.USER_UPDATE + id, data);
};

const updateAddDataRequest = function (data) {
    const itemsUser = SessionStorageService.getUser();
    data.companyId = itemsUser.companyId;
    data.createdBy = itemsUser.id;
};

const updateEditDataRequest = function (data) {
    const itemsUser = SessionStorageService.getUser();
    data.companyId = itemsUser.companyId;
    data.updatedBy = itemsUser.id;
};
