/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Table, Form, Tag, Row, Col, Button } from 'antd';
import styled from 'styled-components';
import { isLoginSession, getListLoginHistory, Constant } from '../../api';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { InputTKG, SelectTKG, RangePickerTKG } from 'tkg-composite-ui';

const TableStyle = styled(Table)`
    .ant-table-thead > tr > th {
        color: #fff;
        background: #154d76;
    }
`;

const columns = [
    {
        title: 'Name',
        dataIndex: 'user',
        key: 'user',
        render: (text) => (
            <b style={{ color: '#154d76' }}>
                {text.first_name} {text.surname}
            </b>
        ),
    },
    {
        title: 'Date Time',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (datetime) => <Tag>{moment(datetime).format('DD-MMM-YYYY hh:mm ')}</Tag>,
    },
    {
        title: 'Company name',
        dataIndex: 'companyname',
        key: 'companyname',
    },
    {
        title: 'IP Address',
        dataIndex: 'ip',
        key: 'ip',
    },
    {
        title: 'Location',
        key: 'country_name',
        dataIndex: ['country_name', 'city'],
        render: (country_name, row) => (
            <Tag>
                {row.country_name} - {row.city}
            </Tag>
        ),
    },
    {
        title: 'Device/Browser',
        dataIndex: 'device',
        key: 'device',
        render: (device, row) => (
            <Tag>
                {row.device} - {row.browser}
            </Tag>
        ),
    },
];

const FilterTable = ({ setFilter, location }) => {
    const selectLocation = location.map((loc) => {
        return { label: loc.city, value: loc.city };
    });
    const onFinish = (fieldsValue) => {
        // console.log(fieldsValue);
        const rangeValue = fieldsValue['range-picker'];
        const values = {
            ...fieldsValue,
            date_from: rangeValue ? rangeValue[0].format('YYYY-MM-DD') : undefined,
            date_to: rangeValue ? rangeValue[1].format('YYYY-MM-DD') : undefined,
            // 'range-picker': rangeValue ? [rangeValue[0].format('YYYY-MM-DD'), rangeValue[1]?.format('YYYY-MM-DD')] : undefined,
        };
        setFilter(values);
    };
    return (
        <>
            <Form
                name="time_related_controls"
                layout="vertical"
                onFinish={onFinish}
                style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    padding: 15,
                    marginBottom: 24,
                    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)',
                }}
            >
                <Row gutter={[16, 16]} align="middle" justify="center">
                    <Col span={6}>
                        <Form.Item name="range-picker" style={{ marginBottom: 0 }}>
                            <RangePickerTKG />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="name" style={{ marginBottom: 0 }}>
                            <InputTKG placeholder="Search Name" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="city" style={{ marginBottom: 0 }}>
                            <SelectTKG
                                showSearch
                                allowClear
                                placeholder="Select a city"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={selectLocation}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ alignSelf: 'center ' }}>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const HistoryLogin = () => {
    const navigate = useNavigate();
    const [listData, setListData] = useState([]);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [location, setLocation] = useState([]);
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    const getListUsers = async () => {
        setLoading(true);
        const dataPage = {
            page: page,
            per_page: 10,
            ...filter,
        };
        const result = await getListLoginHistory(dataPage);
        if (result?.data?.meta?.status === 200) {
            setLoading(false);
            setLocation(result?.data?.response?.city);
            setHistory(result?.data?.response?.history);
            const listWidthCompany = result?.data?.response?.history?.data.map((history) => {
                const companyname = result?.data?.response?.companies.filter((x) => x.id === history?.user?.company_id)[0].name;
                return { ...history, companyname: companyname };
            });
            setListData(listWidthCompany);
        }
    };
    useEffect(() => {
        if (isLoginSession() === false) {
            return navigate(Constant.Pages.Login);
        }
        getListUsers();
    }, []);

    useEffect(() => {
        getListUsers();
    }, [page, filter]);

    useEffect(() => {
        setPage(1);
    }, [filter]);

    const onChangePage = (page) => {
        setPage(page.current);
    };
    return (
        <>
            <FilterTable setFilter={setFilter} location={location} />
            <TableStyle
                loading={loading}
                columns={columns}
                onChange={onChangePage}
                dataSource={listData}
                pagination={{ total: history?.total, showSizeChanger: false, current: page }}
                size="small"
            />
        </>
    );
};

export default HistoryLogin;
