/* eslint-disable react-hooks/exhaustive-deps */
import { Spin, Button } from 'antd';
import Typography from 'antd/es/typography/Typography';
import React from 'react';
import './verifyAccount.scss';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyRegiter } from '../api';

const VerifyAccount = () => {
    const navigate = useNavigate();
    const token = useParams();
    const [loading, setLoading] = React.useState('true');
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);

    React.useEffect(() => {
        const fetchVerify = async () => {
            return await verifyRegiter(token.token);
        };
        fetchVerify().then((response) => {
            setLoading(false);
            if (response.data.meta.status === 200) {
                setSuccess(true);
            } else {
                setFailed(true);
            }
        });
    }, []);

    return (
        <div className="verify-background">
            {loading && <Spin style={{ margin: 'auto' }} />}
            {success && (
                <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <Typography className="title-verify title-verify-done">Verify account done!</Typography>
                    <CheckCircleOutlined style={{ fontSize: '120px', color: 'blue', margin: 'auto' }} />
                    <br />
                    <Button type="primary" className="btnTKGAction" style={{ width: '100%', marginTop: '0.5rem' }} onClick={() => navigate('/login')}>
                        Back to Login
                    </Button>
                </div>
            )}
            {failed && (
                <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <Typography className="title-verify title-verify-failed">Verify account failed!</Typography>
                    <CloseCircleOutlined style={{ fontSize: '120px', color: 'red', margin: 'auto' }} />
                    <br />
                    <Button type="primary" className="btnTKGAction" style={{ width: '100%', marginTop: '0.5rem' }} onClick={() => navigate('/register')}>
                        Back to Register
                    </Button>
                </div>
            )}
        </div>
    );
};

export default VerifyAccount;
