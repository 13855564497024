import React, { useReducer } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import User from './pages/user';
import Signout from './pages/signout';
import Role from './pages/role';
import Dashboard from './pages/dashboard';
import VerifyAccount from './pages/verifyAccount';
import Register from './pages/register';
import ChangePassword from './pages/changePassword';
import ManageUser from './pages/manageUser';
import Verify from './pages/verify';
import { Constant } from './api/services/constant';
import * as ACTIONS from '../src/store/actions/actions';
import * as TimerReducer from '../src/store/reducers/timerReducer';
import Context from './utils/context';

import { ConfigProvider } from 'antd';
import LayoutCommon from './components/layout';
import Profile from './pages/profile';

sessionStorage.setItem('from-iframe', JSON.stringify(false));

export default function Root(props) {
    const [stateTimerReducer, dispatchTimerReducer] = useReducer(TimerReducer.TimerReducer, TimerReducer.initialState);
    const handleShowModal = () => {
        dispatchTimerReducer(ACTIONS.showModal());
    };
    const handleHideModal = () => {
        dispatchTimerReducer(ACTIONS.hideModal());
    };
    return (
        <Context.Provider
            value={{
                isOpenModal: stateTimerReducer.isOpenModal,
                showModal: () => handleShowModal(),
                hideModal: () => handleHideModal(),
            }}
        >
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: 'Nunito Sans, sans-serif',
                        colorPrimary: '#0288d1',
                        colorBorder: '#e3e6ef',
                        borderRadius: 8,
                        colorTextPlaceholder: '#b7c2c5',
                        controlHeightLG: 42,
                        fontSizeLG: 14,
                    },
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route path={Constant.Pages.Signout} element={<Signout />} />
                        <Route path={Constant.Pages.Register} element={<Register />} />
                        <Route path={Constant.Pages.VerifyAccount} element={<VerifyAccount />} />
                        <Route path={Constant.Pages.Verify} element={<Verify />} />
                        <Route path={Constant.Pages.ChangePassword} element={<ChangePassword />} />
                        <Route path={Constant.Pages.Login} element={<Login isLogin={true} />} />
                        <Route path={Constant.Pages.ForgotPassword} element={<Login isLogin={false} />} />
                        <Route path="/" element={<LayoutCommon />}>
                            <Route path={Constant.Pages.Dashboard} element={<Dashboard />} />
                            <Route path={Constant.Pages.Role} element={<Role />} />
                            <Route path={Constant.Pages.User} element={<User />} />
                            <Route path={Constant.Pages.ManageUser} element={<ManageUser />} />
                            <Route path={Constant.Pages.Profile} element={<Profile />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ConfigProvider>
        </Context.Provider>
    );
}
