export const LocalStorageService = {
    companyCodeKey: "company_code",
    emailKey: "email",    
    isLoginKey: "isLogin",
    setCompanyCode: function (data) {
        localStorage.setItem(this.companyCodeKey, data);
    },
    getCompanyCode: function () {
        return localStorage.getItem(this.companyCodeKey)
    },
    removeCompanyCode: function () {
        localStorage.removeItem(this.companyCodeKey);
    },

    setEmail: function (data) {
        localStorage.setItem(this.emailKey, data);
    },
    getEmail: function () {
        return localStorage.getItem(this.emailKey)
    },
    removeEmail: function () {
        localStorage.removeItem(this.emailKey);
    },

    setIsLogin: function (data) {
        localStorage.setItem(this.isLoginKey, data);
    },
    getIsLogin: function () {
        return localStorage.getItem(this.isLoginKey)
    },

    setLoginData: function(companyCode, email) {
        this.setCompanyCode(companyCode);
        this.setEmail(email);
    }
}

export const getCompanyCodeLocalStorage = () => {
    return LocalStorageService.getCompanyCode() || '';
}

export const getEmailLocalStorage = () => {
    return LocalStorageService.getEmail() || '';
}